import { Card, IconButton, injectModStyles } from 'src/components';
import { ModStylesUtil } from 'src/types';
import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  background-color: #00000066;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 960px) {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
  }
`;

export const ModalCard = styled(Card)<{
  width?: string;
}>`
  display: flex;
  flex-flow: column;
  width: ${({ width }) => width || 'max-content'};
  min-height: 30%;
  height: ${({ height }) => height || 'max-content'};
  overflow-y: hidden;
  padding: 0;
  position: relative;
  @media (max-width: 960px) {
    min-width: 100vw;
    height: max-content;
    max-height: 100vh;
    overflow-y: auto;
    border-radius: 0;
  }
  @media (min-width: 960px) {
    max-height: calc(100vh - 8rem);
  }
`;

export const ModalChild = styled.div<{
  isConfirmationModal?: boolean 
}>`
  overflow-x: hidden;
  height: 100%;
  padding: 0rem 1.875rem 1.875rem 1.875rem;
  @media (max-width: 960px) {
    padding-block: 0px 60px;
    height: max-content;
  }
`;

export const ModalChildContainer = styled.div<{
  modStyles?: ModStylesUtil;
}>`
  width: inherit;
  height: 100%;
  ${(props) => props.modStyles && injectModStyles(props.modStyles)}
`;

export const ModalCloseButton = styled(IconButton)`
  display: flex;
  width: 20%;
  height: auto;
  justify-content: flex-end;
  padding: 1.875rem 1.875rem 1rem 0;
  align-self: flex-end;
  path {
    stroke-width: 0;
  }
`;
