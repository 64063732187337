import {
  BackButton,
  Button,
  Column,
  ConfirmationPrompt,
  Row,
  Text,
} from 'src/components';
import { handleDownload, removeModalHash, theme } from 'src/utils';
import {
  AddDocument,
  BulkUploadIcon,
  ExclamationWarnIcon,
  LeftSideArrow,
} from 'src/assets/icons';
import { ActionTable } from 'src/pages/admin/styled';
import { Modal } from 'src/containers';
import ReactTooltip from 'react-tooltip';
import { GET_PATIENTS, GET_PRODUCTS } from 'src/constants';
import { useToast } from 'src/state';
import { Dropzone, FileInput } from './styled';
import { Loader } from './Loader';
import { getColumns, getData } from './utils';
import { useBulkUpload } from './hooks/useBulkUpload';

const bulkQueries = {
  patients: GET_PATIENTS,
  inventory: GET_PRODUCTS,
};

const bulkData = {
  patients: 'patientBulkUpload',
  inventory: 'inventoryBulkUpload',
};

const templateUrl = {
  patients:
    process.env.REACT_APP_PATIENTS_TEMPLATE_URL ||
    'https://res.cloudinary.com/afuadajo/raw/upload/v1739362680/Ankora_Patient_Reg_Template_g8m1fu.csv',
  inventory:
    process.env.REACT_APP_INVENTORY_TEMPLATE_URL ||
    'https://res.cloudinary.com/afuadajo/raw/upload/v1739362680/Ankora_Inventory_Template_lamtex.csv',
};

const BulkUpload = () => {
  const {
    view,
    setView,
    file,
    inputRef,
    isDragging,
    error,
    progress,
    mockLoading,
    result,
    page,
    setPage,
    onDrop,
    onDragOver,
    onDragLeave,
    onChange,
    modalView,
    getFileSizeString,
    resetInput,
    rowConfig,
    operations,
  } = useBulkUpload();
  const { showToast } = useToast();

  const saveBulk = () => {
    if (file) {
      operations.saveBulk({
        variables: {
          file,
        },
        refetchQueries: [bulkQueries[modalView]],
        awaitRefetchQueries: true,
        onCompleted(d) {
          if (d?.[bulkData[modalView]]) {
            showToast(d?.[bulkData[modalView]]?.message, 'success');
            setView('success');
          } else {
            showToast('An error occurred', 'error');
          }
        },
      });
    }
  };

  return (
    <Modal>
      <Column
        align="center"
        justify="center"
        height="100%"
        gap={0.5}
        width="100%"
      >
        {view === 'landing' && (
          <ConfirmationPrompt
            onConfirm={() => setView('dropzone')}
            onDismiss={() =>
              handleDownload(
                templateUrl[modalView] as string,
                `ankora-${modalView}-template.csv`,
              )}
            title="Bulk Upload"
            html="Please click <strong>download</strong> to use the template provided or upload your <strong>csv</strong> files using our template <strong>headers</strong>"
            confirmText="Upload File"
            dismissText="Download Template"
          />
        )}
        {view === 'dropzone' && (
          <Column
            gap={0.5}
            width="100%"
            align="center"
            justify="center"
            height="100%"
          >
            <Text
              size="lg"
              weight="bold"
              modStyles={{ mb: 1 }}
              color={theme.black[100]}
            >
              Upload File
            </Text>
            {!mockLoading && (
              <Dropzone
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onClick={() => inputRef.current?.click()}
                active={isDragging}
                align="center"
                gap={1}
                modStyles={{ py: 2 }}
              >
                <FileInput
                  type="file"
                  name="file"
                  ref={inputRef}
                  accept=".csv"
                  onChange={onChange}
                />
                <BulkUploadIcon />
                <p>
                  <Text weight="semibold" color={theme.secondary[700]}>
                    Click to upload
                  </Text>{' '}
                  or drag and drop the CSV file
                </p>
              </Dropzone>
            )}
            {mockLoading && (
              <Loader
                progress={progress}
                filename={file?.name || ''}
                fileSize={getFileSizeString(file?.size || 0)}
              />
            )}
          </Column>
        )}
        {view === 'preview' && result && (
          <Column width="100%" gap={1} minWidth="80vw">
            <Row justify="space-between" width="100%">
              <BackButton
                label={`Back to Manage ${modalView}`}
                link={`/admin/${modalView?.toLowerCase()}`}
                icon={<LeftSideArrow />}
                onClick={removeModalHash}
              />
              <Row
                align="center"
                width="max-content"
                data-tip
                data-for="error-tooltip"
              >
                <ExclamationWarnIcon />
                <Text
                  weight="semibold"
                  color="red"
                  modStyles={{ cursor: 'pointer', ma: 0 }}
                >
                  You have {result.errors.length} error(s) in this file{' '}
                </Text>
                <ReactTooltip id="error-tooltip" place="top" effect="solid">
                  <Column>
                    {result.errors.map((err, index) => (
                      <Text
                        key={index}
                        size="sm"
                        color="#fff"
                        modStyles={{ ma: 0 }}
                      >
                        Row {err.row}
                      </Text>
                    ))}
                  </Column>
                </ReactTooltip>
              </Row>

              <Button
                background={theme.primary[700]}
                data-testid="sell-product"
                width="max-content"
                color={theme.white[100]}
                onClick={resetInput}
                disabled={false}
              >
                <AddDocument />
                Change File
              </Button>
            </Row>
            <ActionTable
              data={
                getData(result.data).slice(
                  (page - 1) * 10,
                  page * 10,
                ) as unknown as Record<string, unknown>[]
              }
              columns={getColumns(rowConfig[modalView]?.validation || {})}
              hasTooltip
              pagination={{
                page,
                size: result.data.length || 0,
                total: result.data.length || 0,
              }}
              handleGoToPage={(index) => setPage(index)}
            />
            <Row justify="space-between" width="100%">
              <Button
                variant="cancel"
                data-testid="sell-product"
                width="max-content"
                onClick={removeModalHash}
                disabled={operations?.saveBulkLoading}
                modStyles={{ px: 2 }}
              >
                cancel
              </Button>
              <Button
                data-testid="bulk-add"
                width="max-content"
                onClick={saveBulk}
                isLoading={operations?.saveBulkLoading}
                disabled={result?.errors?.length > 0}
              >
                Upload File
              </Button>
            </Row>
          </Column>
        )}
        {view === 'success' && (
          <ConfirmationPrompt
            onConfirm={() => setView('dropzone')}
            title="File Upload Succesful"
            message={`${result?.data.length} items successfully uploaded`}
            confirmText="Upload Another File"
            dismissText="Close"
          />
        )}
        {error && (
          <Text size="sm" color={theme.red[500]}>
            Invalid file. Please upload a CSV file
          </Text>
        )}
      </Column>
    </Modal>
  );
};

export default BulkUpload;
