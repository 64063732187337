import { gql } from '@apollo/client';

export const ACKNOWLEDGE_INVITE = gql`
  mutation AcknowledgeInvite($input: AcknowledgeInviteInput!) {
    acknowledgeInvite(input: $input) {
      accessToken
      refreshToken
      success
      message
    }
  }
`;

export const INVITE_NEW_STAFF = gql`
  mutation InviteStaff($input: InviteStaffInput!) {
    inviteStaff(input: $input) {
      success
      message
      invitations {
        id: _id
        isAccepted
      }
    }
  }
`;

export const GET_STAFF_GROUPS = gql`
  query StaffGroups($input: GetStaffGroupsQuery) {
    getStaffGroups(input: $input) {
      staffGroups {
        id: _id
        name
        suggestedPermissions {
          id: _id
          name
        }
      }
      total
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query GetPermissions {
    getPermissions {
      id: _id
      name
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  query Query($input: ForgotPasswordInput) {
    forgotPassword(input: $input)
  }
`;

export const MAGIC_LINK_SIGN_IN = gql`
  query MagicLinkSignin($token: String) {
    magicLinkSignin(token: $token) {
      accessToken
      refreshToken
      staff {
        id: _id
        user {
          id: _id
          firstName
          lastName
          fullName
          address
          emails
        }
        facility {
          id: _id
          address
          state
          country
          organisation {
            id: _id
            name
            licenceNumber
            onboardingSteps {
              welcome
              inviteStaff
              setupServices
            }
          }
        }
        staffGroup {
          id: _id
          name
        }
        permissions {
          id: _id
          name
        }
        status
      }
    }
  }
`;

export const GET_PATIENTS_META = gql`
  query GetPatients($query: GetPatientsInput) {
    getPatients(query: $query) {
      patients {
        id: _id
        user {
          fullName
          firstName
          lastName
        }
      }
    }
  }
`;

export const SEND_MAGIC_LINK = gql`
  query Query($email: String) {
    sendMagicLink(email: $email)
  }
`;

export const GET_STAFFS_META = gql`
  query GetStaffs($query: StaffSearchQuery) {
    getStaffs(query: $query) {
      staff {
        ... on Staff {
          id: _id
          user {
            fullName
            profileAvatar
          }
          staffGroup {
            name
          }
        }
      }
    }
  }
`;

export const DELETE_STAFF = gql`
  mutation DeleteStaff($staffId: ID!) {
    deleteStaff(staffId: $staffId) {
      id: _id
      user {
        fullName
      }
    }
  }
`;

export const DELETE_SERVICE = gql`
  mutation DeleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_STAFF = gql`
  mutation UpdateStaff($input: UpdateStaffInput!, $staffId: ID!) {
    updateStaff(input: $input, staffId: $staffId) {
      id: _id
      status
    }
  }
`;

export const ACKNOWLEDGE_STAFF_INVITE = gql`
  mutation AcknowledgeInvite(
    $input: AcknowledgeInviteInput!
    $facilityId: ID!
  ) {
    acknowledgeInvite(input: $input, facilityId: $facilityId) {
      success
      message
      invitation {
        id: _id
        facility {
          id: _id
          state
          country
        }
      }
    }
  }
`;

export const MERGE_INVITE_ACCOUNT = gql`
  mutation MergeInviteAccount(
    $input: MergeInviteAccountInput!
    $facilityId: ID!
  ) {
    mergeInviteAccount(input: $input, facilityId: $facilityId) {
      success
      message
    }
  }
`;

export const GET_INVITE_INFO = gql`
  query GetInviteInfo($invitationId: ID!) {
    getInviteInfo(invitationId: $invitationId) {
      id: _id
      user {
        firstName
        lastName
        fullName
      }
      facility {
        organisation {
          id: _id
          name
        }
      }
      meta {
        email
        staffGroup {
          id: _id
          name
        }
        permissions {
          id: _id
          name
        }
      }
      isAccepted
    }
  }
`;

export const GET_ORGANISATIONS = gql`
  query GetOrganisations {
    getOrganisations {
      id: _id
      name
      isVerified
    }
  }
`;

export const GET_USER_BY_TOKEN = gql`
  query GetUserByToken($token: String!) {
    getUserByToken(token: $token) {
      id: _id
      emails
      firstName
      lastName
      fullName
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query GetUser {
    getUser {
      id: _id
      firstName
      lastName
      emails
      profileAvatar
      staff {
        id: _id
        facility {
          id: _id
          address
          organisation {
            id: _id
            name
          }
        }
      }
    }
  }
`;

export const INVITE_SIGN_UP = gql`
  mutation InviteSignup($input: InviteSignupInput!) {
    inviteSignup(input: $input) {
      accessToken
      refreshToken
      user {
        id: _id
        firstName
        lastName
        fullName
        emails
      }
      staff {
        id: _id
        staffGroup {
          name
        }
        permissions {
          name
        }
      }
    }
  }
`;

export const CREATE_TODOS = gql`
  mutation CreateTodo($input: CreateTodoInput!) {
    createTodo(input: $input) {
      success
      message
      todo {
        id: _id
      }
    }
  }
`;

export const GET_TODOS = gql`
  query GetTodos($input: GetTodosInput) {
    getTodos(input: $input) {
      todos {
        title
        dueDate
        dueTime
        id: _id
        description
        notes {
          id: _id
          comment
        }
        assignee {
          id: _id
          user {
            firstName
            lastName
            fullName
            profileAvatar
          }
          staffGroup {
            name
          }
        }
        creator {
          id: _id
          user {
            firstName
            lastName
            fullName
            profileAvatar
          }
          staffGroup {
            name
          }
        }
        status
        patient {
          id: _id
          user {
            firstName
            lastName
            fullName
          }
        }
      }
      total
    }
  }
`;

export const SEARCH_TODOS = gql`
  query SearchTodos($input: SearchTodosInput!) {
    searchTodos(input: $input) {
      todos {
        title
        dueDate
        dueTime
        id: _id
        description
        notes {
          id: _id
          comment
        }
        assignee {
          id: _id
          user {
            firstName
            lastName
            fullName
          }
        }
        status
        patient {
          id: _id
          user {
            firstName
            lastName
            fullName
          }
        }
      }
      total
    }
  }
`;

export const ADD_TODO_NOTES = gql`
  mutation AddTodoNotes($input: AddTodoNotesInput!) {
    addTodoNotes(input: $input) {
      success
      message
      todo {
        notes {
          comment
          id: _id
        }
      }
    }
  }
`;

// export const GET_RECORD_TEMPLATES = gql`
//   query RecordTemplates($input: GetRecordTemplatesOrRecordsInput) {
//     getRecordTemplates(input: $input) {
//       recordTemplates {
//         id: _id
//         title
//       }
//     }
//   }
// `;

// export const GET_RECORD_TEMPLATE = gql`
//   query GetRecordTemplate($input: ID!) {
//     getRecordTemplate(input: $input) {
//       title
//       description
//       fields
//     }
//   }
// `;

export const GET_RECORDS = gql`
  query GetRecords($input: GetRecordTemplatesOrRecordsInput) {
    getRecords(input: $input) {
      records {
        id: _id
        data
        facility {
          address
        }
        provider {
          user {
            firstName
            lastName
          }
        }
        template {
          title
          description
        }
        createdAt
      }
    }
  }
`;

// export const GET_RECORD = gql`
//   query GetRecord($input: ID!) {
//     getRecord(input: $input) {
//       id: _id
//       data
//     }
//   }
// `;

// export const ADD_RECORD = gql`
//   mutation AddRecord($input: AddRecordInput) {
//     addRecord(input: $input) {
//       success
//       message
//     }
//   }
// `;

export const ADD_RECORD_V2 = gql`
  mutation AddRecordTemplate($input: AddRecordTemplateInput) {
    addRecordTemplate(input: $input) {
      message
      recordTemplate {
        _id
        fields {
          id: _id
          title
          type
          ... on DateField {
            id: _id
            placeholder
            title
            type
            validate
          }
          ... on MultiSelectField {
            id: _id
            options {
              label
              value
            }
            placeholder
            title
            type
            validate
          }
          ... on NumberField {
            id: _id
            placeholder
            title
            type
            validate
          }
          ... on TextField {
            id: _id
            placeholder
            title
            type
            validate
          }
          ... on SelectField {
            id: _id
            options {
              label
              value
            }
            title
            type
            validate
          }
          ... on CompoundField {
            fields {
              id: _id
              title
              type
              ... on DateField {
                id: _id
                placeholder
                title
                type
                validate
              }
              ... on MultiSelectField {
                id: _id
                options {
                  label
                  value
                }
                placeholder
                title
                type
                validate
              }
              ... on NumberField {
                id: _id
                placeholder
                title
                type
                validate
              }
              ... on TextField {
                id: _id
                placeholder
                title
                type
                validate
              }
              ... on SelectField {
                options {
                  label
                  value
                }
                id: _id
                title
                type
                validate
              }
            }
            id: _id
            title
            type
          }
        }
      }
    }
  }
`;

export const UPDATE_TODO = gql`
  mutation UpdateTodo($todoId: ID!, $input: UpdateTodoInput!) {
    updateTodo(todoId: $todoId, input: $input) {
      success
      message
      todo {
        id: _id
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    getNotifications {
      id: _id
      message
      action
      status
      createdAt
      user {
        id: _id
        firstName
        lastName
        fullName
        emails
      }
      createdAt
      updatedAt
    }
  }
`;

export const MARK_ALL_NOTIFICATIONS = gql`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead {
      message
      success
    }
  }
`;

export const GET_APPOINTMENT_METADATA = gql`
  query GetAppointmentMeta {
    getAppointmentMeta {
      status
      frequency
      types
    }
  }
`;

export const GET_APPOINTMENT_TIMES = gql`
  query GetAppointments($input: GetAppointmentsInput) {
    getAppointments(input: $input) {
      id: _id
      visitTime {
        startTime
        endTime
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      isPasswordSet
    }
  }
`;

export const UPDATE_NOTIFICATIONS = gql`
  mutation UpdateNotificationSettings($input: NotificationEmailSettingsInput!) {
    updateNotificationSettings(input: $input) {
      emails
    }
  }
`;

export const GET_PATIENT_META = gql`
  query GetPatientMeta {
    getPatientMeta {
      status
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    getUser {
      id: _id
      firstName
      lastName
      fullName
      address
      emails
      isPasswordSet
      password
      isTourComplete
      profileAvatar
      staff {
        id: _id
        facility {
          id: _id
          address
          organisation {
            logo
            name
            onboardingSteps {
              welcome
              setupServices
              inviteStaff
            }
            subscription {
              id: _id
              isAutoCharge
              billingFrequency
              freeTrialEnd
              isFreeTrial
              isGracePeriod
              name
              nextBillingDate
              period
              price
              status
            }
          }
        }
      }
    }
  }
`;

export const ADMIN_UPDATE_USER = gql`
  mutation AdminUpdateUser($userId: ID!, $input: SuperAdminUpdateUserInput) {
    adminUpdateUser(userId: $userId, input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      message
      success
    }
  }
`;

export const DELETE_TODOS = gql`
  mutation DeleteTodo($input: ID!) {
    deleteTodo(input: $input) {
      success
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput) {
    resetPassword(input: $input) {
      success
      message
    }
  }
`;

export const GET_META_DATA = gql`
  query Meta {
    getAppointmentMeta {
      status
      types
    }
    getIdentityTypes
  }
`;
