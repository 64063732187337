import _ from 'lodash';

export const generateAvatar = (name: string, size?: number) => {
  const trimmedName = _.trim(name);
  const nameArray =
    _.split(trimmedName, ' ').length > 1
      ? _.split(trimmedName, ' ')
      : _.split(trimmedName, '');
  const names = _.filter(nameArray, Boolean);

  const avatarSize = '100%';
  const avatarBackgroundColor = 'grey';
  const textColor = 'white';

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${avatarSize} ${avatarSize}" width="${avatarSize}" height="${avatarSize}">
      <circle cx="50%" cy="50%" r="50%" fill="${avatarBackgroundColor}" />
      <text x="50%" y="50%" dominant-baseline="central" text-anchor="middle" font-family="Nunito, Arial, sans-serif" font-size="${
        size || '1rem'
      }" font-weight="500" fill="${textColor}">${names?.[0]?.[0]?.toUpperCase()}${names?.[1]?.[0]?.toUpperCase()}</text>
    </svg>
  `;

  return `data:image/svg+xml;base64,${btoa(svgContent)}`;
};
