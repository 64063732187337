import { gql } from '@apollo/client';

export const GET_PERMISSIONS_CONFIG = gql`
  query GetFeaturesPermissionsConfig {
    getFeaturesPermissionsConfig {
      PATIENT_JOURNEY_AND_IN_PATIENTS {
        name
        description
        dependencies
      }
      MANAGE_STEPS {
        name
        description
        dependencies
      }
      MANAGE_STAFFS {
        name
        description
        dependencies
      }
      MANAGE_SERVICES {
        name
        description
        dependencies
      }
      MANAGE_PATIENTS {
        dependencies
        description
        name
      }
      MANAGE_ORGANISATION {
        dependencies
        description
        name
      }
      MANAGE_INVENTORY {
        dependencies
        description
        name
      }
      MANAGE_HMO {
        name
        description
        dependencies
      }
      MANAGE_FACILITIES {
        name
        description
        dependencies
      }
      MANAGE_APPOINTMENTS {
        dependencies
        description
        name
      }
      MANAGE_REPORTS {
        dependencies
        description
        name
      }
      DASHBOARD {
        dependencies
        description
        name
      }
      PLAN_AND_BILLINGS {
        dependencies
        description
        name
      }
    }
  }
`;