import React, { useEffect } from 'react';
import { Column, Text } from 'src/components';
import { BuildSideBarItemsProps, SidebarConfig } from 'src/types';
import useMediaQuery from 'src/utils/useMediaQuery';
import { theme } from 'src/utils';
import useClickAway from 'src/utils/useClickAway';
import { useTour } from '@reactour/tour';
import { useStaff, useUser } from 'src/state';
import { HR, SidebarItems, SubGroupItemsWrapper } from './styled';
import { SideBarButtonItem } from './SideBarButtonItem';

const TitleText: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Text
      align="left"
      size="xs"
      color={theme.grey[700]}
      modStyles={{ ml: '0.625rem', my: '0.25rem' }}
    >
      {children}
    </Text>
  );
};

const SubGroupItem: React.FC<{
  subGroup: SidebarConfig;
  isVisible: boolean;
  onClick?: () => void;
}> = ({ subGroup, isVisible, onClick }) => {
  const { isComponentVisible, setIsComponentVisible } = useClickAway(isVisible);
  if (subGroup?.group?.length === 0 || !isComponentVisible) return <></>;

  return (
    <SubGroupItemsWrapper>
      <TitleText>{subGroup.title}</TitleText>
      {subGroup?.group?.map((item, index) => {
        return (
          <SidebarItems key={`sub-menu-item-${index}`}>
            <SideBarButtonItem
              item={item}
              onClick={() => {
                setIsComponentVisible(false);
                onClick?.();
              }}
            />
          </SidebarItems>
        );
      })}
    </SubGroupItemsWrapper>
  );
};

export const BuildSideBarItems: React.FC<BuildSideBarItemsProps> = ({
  config,
  expand,
  onClick,
}) => {
  const isMobile = !useMediaQuery('(min-width: 960px)');
  const [activeSubGroup, setActiveSubGroup] = React.useState<string | null>(
    null,
  );
  // const { isComponentVisible, setIsComponentVisible } =
  //   useClickAway(false);
  const { setIsOpen } = useTour();
  const { staff } = useStaff();
  const { user } = useUser();
  const isTourComplete = user?.getUser?.isTourComplete;
  const staffGroup = staff?.getStaff?.staffGroup?.name;

  useEffect(() => {
    if (!expand) {
      setActiveSubGroup(null);
    }
    const onboardingSteps =
      staff &&
      Object.values(staff?.getStaff?.facility?.organisation?.onboardingSteps);
    let timer: NodeJS.Timeout;
    if (
      (staffGroup === 'Owner' &&
        !onboardingSteps?.includes(false) &&
        !isTourComplete) ||
      (staffGroup !== 'Owner' && !isTourComplete)
    ) {
      timer = setTimeout(() => {
        setIsOpen(true);
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [expand, isTourComplete, setActiveSubGroup, setIsOpen, staff, staffGroup]);

  // const SubGroupItem: React.FC<{ subGroup: SidebarConfig }> = ({
  //   subGroup,
  // }) => {
  //   if (subGroup?.group?.length === 0 || !isComponentVisible || !expand)
  //     return <></>;

  //   return (
  //     <SubGroupItemsWrapper>
  //       <TitleText>{subGroup.title}</TitleText>
  //       {subGroup?.group?.map((item, index) => {
  //         return (
  //           <SidebarItems key={`sub-menu-item-${index}`}>
  //             <SideBarButtonItem
  //               item={item}
  //               onClick={() => {
  //                 setIsComponentVisible(false)
  //                 onClick?.()
  //               }}
  //             />
  //           </SidebarItems>
  //         );
  //       })}
  //     </SubGroupItemsWrapper>
  //   );
  // };

  return (
    <>
      {config.map((item, index) => {
        if (item.subGroup && isMobile) {
          return (
            <React.Fragment key={`sb-item-${index}`}>
              {item.subGroup?.group?.map((content, indx) => {
                if (!content.showOnMobile) return null;
                return (
                  <SidebarItems key={`sub-menu-item-${indx}-${content.path}`}>
                    <SideBarButtonItem
                      item={content}
                      onClick={() => onClick?.()}
                    />
                  </SidebarItems>
                );
              })}
            </React.Fragment>
          );
        }

        if (item.group) {
          return (
            <Column gap={0.25} key={`sb-line-group-${index}`}>
              {!isMobile && (
                <Text
                  align="left"
                  size="xs"
                  color={theme.grey[700]}
                  modStyles={{ ml: '0.625rem', my: '0.25rem' }}
                >
                  {item.title}
                </Text>
              )}
              <SidebarItems>
                <Column gap={0.25} align="center" width="100%">
                  <BuildSideBarItems
                    config={item.group}
                    expand={expand}
                    onClick={() => onClick?.()}
                  />
                </Column>
                {index < config.length - 1 && !isMobile && (
                  <HR key={`sb-line-${index}`} />
                )}
              </SidebarItems>
            </Column>
          );
        }

        return (
          <React.Fragment key={`sb-item-${index}`}>
            <SideBarButtonItem
              item={item}
              onClick={() => {
                if (item.subGroup) {
                  if (item.subGroup.title === activeSubGroup) {
                    setActiveSubGroup(null);
                  } else {
                    setActiveSubGroup(item.subGroup?.title);
                  }
                }
                onClick?.();
              }}
            />
            {item.subGroup && (
              <SubGroupItem
                subGroup={item.subGroup}
                onClick={onClick}
                isVisible={item.subGroup.title === activeSubGroup}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
