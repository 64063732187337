import React from 'react';
import { OperationVariables } from '@apollo/client';
import RowDataContext from './context';

interface RowData {
  [key: string]: OperationVariables;
}

const useRowData = () => {
  const context = React.useContext(RowDataContext);

  if (!context) {
    throw new Error('useRowData must be used within a RowDataProvider');
  }

  const { rowData, setRowData } = context;

  const updateRowData = React.useCallback((key: string, updater: OperationVariables) => {
    setRowData((prevData: RowData) => ({
      ...prevData,
      [key]: updater,
    }));
  }, [setRowData]);

  const getDataByKey = React.useCallback((key: string) => {
    return rowData?.[key];
  }, [rowData]);

  return { rowData, setRowData, updateRowData, getDataByKey };
};

export { useRowData };