export const getColumns = (cols: Record<string, unknown>) => {
  delete cols.idx;
  const columns = Object.keys(cols).map((key) => ({
    title: key,
    dataIndex: key,
  }));
  return [
    {
      title: '',
      dataIndex: 'idx',
      render(val: unknown) {
        return `${val}.`;
      },
    },
    ...columns,
  ];
};

export const getData = (data: Record<string, unknown>[]) => {
  const rows = data.map((row) => {
    const obj: Record<string, unknown> = {};
    Object.keys(row).forEach((key) => {
      if (key === 'errors') {
        obj.tooltipValue = (row[key] as string[]).join(', \n');
        obj.errored = true;
        return;
      }
      obj[key] = row[key] as string;
    });
    return obj;
  });
  return rows;
};

export const openReader = (
  file: File,
  options: {
    onLoad: (result: string) => void;
    onError: (error: ProgressEvent<FileReader>) => void;
    onProgress: (event: ProgressEvent<FileReader>) => void;
  },
) => {
  const reader = new FileReader();
  reader.onprogress = options.onProgress;
  reader.onload = (event) => {
    const result = event.target?.result as string;
    options.onLoad(result);
  };
  reader.onerror = options.onError;
  reader.readAsText(file);
};
