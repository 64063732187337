import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75); 
  z-index: 9999;
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s infinite linear;
`;

export const OverlayLoader = () => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
};