import {
  Button,
  Card,
  Column,
  H6,
  Input,
  Row,
  Text,
  Textarea,
} from 'src/components';
import { currencyformatter, getModalParams } from 'src/utils';
import { useFormik } from 'formik';
import Validator from 'validatorjs';
import { FullWidthForm } from 'src/containers/FormRenderer/styled';
import { SolidCheck } from 'src/assets/icons';
import { GET_INVOICE } from 'src/constants';
import { useToast } from 'src/state';
import { useInventoryInvoiceApis } from 'src/store/inventory-invoices';
import mixpanel from 'mixpanel-browser';
import useMediaQuery from 'src/utils/useMediaQuery';
import ProductInfo from '../components/ProductInfo';
import ProductExtraInfo from '../components/ProductExtraInfo';
import { ReturnItem } from './InvoicePreview';

const ReturnForm: React.FC<{ returnItem: ReturnItem; goBack: () => void }> = ({
  returnItem,
  goBack,
}) => {
  const invoiceId = getModalParams('id');
  const isMobile = !useMediaQuery('(min-width: 960px)');

  const { returnInvoiceItem, returnItemLoading } = useInventoryInvoiceApis();
  const { showToast } = useToast();

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: { quantity: 0, reason: '' },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (validationValues) => {
      const validation = new Validator(validationValues, {
        quantity: `required|min:1|max:${returnItem.units}`,
      });
      validation.passes();
      return validation.errors.errors;
    },
    onSubmit: async (value) => {
      mixpanel.track(`Click 'Return' button`, { feature: 'Inventory Invoice' });
      await returnInvoiceItem({
        variables: {
          input: { ...value, invoiceItem: returnItem.invoiceItemId },
        },
        refetchQueries: [{ query: GET_INVOICE, variables: { invoiceId } }],
        awaitRefetchQueries: true,
        onCompleted(d) {
          if (d?.returnInvoiceItem) {
            showToast(d.returnInvoiceItem?.message, 'success');
            goBack();
          }
        },
      });
      return value;
    },
  });

  return (
    <Column gap={1.5} minWidth="40vw">
      <Column gap={0.3}>
        <H6 modStyles={{ ma: 0 }}>Return Product</H6>
        <Text modStyles={{ ma: 0 }} size="sm" weight="medium">
          Return and Refund
        </Text>
      </Column>
      <Card width="100%">
        <ProductExtraInfo name={returnItem.productDescription} extraInfo="" />
      </Card>
      <Row gap={1}>
        <ProductInfo title="IVOICE ID" info={returnItem?.invoiceId || 'N/A'} />
        <ProductInfo title="Quantity Bought" info={returnItem.units} />
      </Row>
      <Row gap={1}>
        <ProductInfo
          title="Cost Per Item"
          info={currencyformatter.format(
            parseInt(Number(returnItem.unitCost).toString(), 10),
          )}
        />
        <ProductInfo
          title="Out-of-pocket"
          info={returnItem.isHmo ? 'Hmo' : 'Out-of-pocket'}
        />
      </Row>
      <FullWidthForm onSubmit={handleSubmit}>
        <Input
          id="quantity"
          type="number"
          label="Quantity returning"
          value={values.quantity}
          name="quantity"
          placeholder="Enter how many items you want return"
          onChange={handleChange}
          error={errors?.quantity}
        />
        <Textarea
          id="reason"
          label="Notes"
          value={values.reason}
          name="reason"
          placeholder="Short description"
          onChange={handleChange}
        />
        <Button
          variant="solid"
          width={!isMobile ? "max-content" : "100%"}
          type="submit"
          isLoading={returnItemLoading}
          modStyles={{ ml: 'auto' }}
          noIconStyles
        >
          <SolidCheck /> Return
        </Button>
      </FullWidthForm>
    </Column>
  );
};

export default ReturnForm;
