import { ThreeDots } from 'src/assets/icons';
import { Row } from 'src/components';
import { DropdownList, DropdownListItem } from 'src/components/Dropdown/styled';
import { useDropdown } from 'src/hooks/useDropdown';
import { BillingInvoice } from 'src/types';
import { openModal } from 'src/utils/modalHelper';
import { TableActionButton } from './styled';
import { useBilling } from './api';
import { OverlayLoader } from './components/overlay-loader';


interface ActionColumnProps {
  row: BillingInvoice
}

export const BillingActionColumn = ({ row }: ActionColumnProps) => {

  const { id, status } = row

  const dropdowns = [
    {
      value: 'pay-now',
      label: 'Make Payment'
    },
    {
      value: 'make-payment',
      label: 'View Invoice',
    },
  ];

  const isPaidStatus = status?.toLowerCase() === 'paid';
  const dropdownItems = dropdowns.filter(el => {
    const isMakePayment = el.label.toLowerCase() === 'make payment';
    return isPaidStatus ? !isMakePayment : dropdowns;
  });

  const { useConfirmAutoPayment } = useBilling()
  const { initiatePayment, confirmAutoPayment, loading, isAutoCharge } = useConfirmAutoPayment(row?.id)


  const { toggleDropdown, isOpen, Dropdown } = useDropdown();

  const handleDropdownChange = (value: string) => {
    if (value === "pay-now") {
      if (!isAutoCharge) {
        toggleDropdown()
        return confirmAutoPayment()
      }
      toggleDropdown()
      return initiatePayment({ invoiceId: row?.id, callbackUrl: `${window.location.href}#make-payment&id=${row?.id}` })
    }
    openModal(value, id);
    toggleDropdown()
  }


  if (loading) return <OverlayLoader /> 

  return (
    <Row justify='center'>
      <TableActionButton
        type='button' 
        onClick={toggleDropdown}
      >
        <ThreeDots className="icon" />
      </TableActionButton>
      {isOpen && (
        <Dropdown>
          <DropdownList>
            {dropdownItems.map(({ value, label }, idx) => {
              return (
                <DropdownListItem
                  key={`${value}-${idx}`}
                  data-testid={label.toLowerCase()}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDropdownChange(value);
                  }}
                >
                  {label}
                </DropdownListItem>
              );
            })}
          </DropdownList>
        </Dropdown>
      )}
    </Row>
  );
};