/* eslint-disable jsx-a11y/label-has-associated-control */
import { OperationVariables, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { CancelIcon, InfoIcon } from "src/assets/icons";
import { Column, Row, H4, Button, Checkbox, Text } from "src/components";
import { CANCEL_AUTO_CHARGE, INITIATE_PAYMENT, INITIATE_TEST_PAYMENT, PERMISSION_ACTION_MESSAGE, VERIFY_PAYMENT } from "src/constants";
import { GET_BILLING_INVOICE, GET_BILLING_INVOICES } from "src/constants/Queries/billing-invoice";
import { useApolloSearch } from "src/hooks";
import { useAlert, useRowData, useStaff, useToast } from "src/state";
import { GetBillingInvoiceResponse, GetBillingInvoicesResponse } from "src/types";
import { contentsToUrl, downloadCSV, generateCsvContent, getModalParams } from "src/utils";
import Tooltip from "./components/tooltip";


interface InitiatePayment { 
  invoiceId: string, 
  callbackUrl: string,
  isAutoCharge?: boolean
}


const useBilling = () => {
  const { hasPermission } = useStaff()
  const { showToast } =  useToast()
  const id = getModalParams("id");
  const { showAlert, removeAlert } = useAlert()
  
  const useGetBillingInvoices = (value?: OperationVariables, skip?: boolean) => {
    const { searchValue, ...others } = useApolloSearch();
    const result = useQuery<GetBillingInvoicesResponse>(GET_BILLING_INVOICES, {
      errorPolicy: "all",
      skip,
      variables: { 
        input: {
          ...value,
          search: searchValue || undefined,
        },
      }
    });


    const exportDataToCSV = () => {
      const dataToExport = result?.data?.getBillingInvoices?.invoices?.map(invoice => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { staff, subscription, ...rest } = invoice;
        return rest;
      });
      const csvContents = [generateCsvContent(dataToExport as unknown as Record<string, unknown>[], "Billing History")]
      const url = contentsToUrl('Billing Invoice', csvContents)
      downloadCSV(url, 'Billing Invoice')
    }

    return { ...result , searchValue, exportDataToCSV, ...others};
  };

  const useGetBillingInvoice = (value?: string, skip?: boolean) => {
    const { getDataByKey, updateRowData } = useRowData();
    const billing_data = getDataByKey("billing")
    
    const result = useQuery<GetBillingInvoiceResponse>(GET_BILLING_INVOICE, {
      errorPolicy: "all",
      skip: skip || !!billing_data,
      variables: { input: value || id },
      onCompleted: (data) => updateRowData('billing', data?.getBillingInvoice)
    });

    return { ...result };
  };

  const useInitiatePayment = () => {
    const [execute, result] = useMutation(INITIATE_PAYMENT);

    const initiatePayment = ({ invoiceId, callbackUrl, isAutoCharge }: InitiatePayment) => {

      if (!hasPermission("MAKE_SUBSCRIPTION_PAYMENT")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning'); 
      return execute({
        variables: { input: {
          invoiceId, callbackUrl, isAutoCharge
        }},
        onCompleted: (data) => {
          if (data) {
            showToast(data.initiatePayment.message, 'success')
            window.location.href =  data.initiatePayment.data.payment.authorization_url
          }
        }
      });
    }

    return { ...result, execute, initiatePayment };
  };



  const useInitiateTestPayment = () => {
    const [execute, result] = useMutation(INITIATE_TEST_PAYMENT);

    const initiateTestPayment = ({ callbackUrl, onCompleted }:  { callbackUrl: string, onCompleted?: VoidFunction }) => {

      if (!hasPermission("MAKE_SUBSCRIPTION_PAYMENT")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning'); 
      return execute({
        variables: { input: { callbackUrl  }},
        onCompleted: (data) => {
          if (data) {
            onCompleted?.()
            showToast(data.initiateTestPayment.message, 'success')
            window.location.href =  data.initiateTestPayment.data.payment.authorization_url
          }
        },
      });
    }

    return { ...result, execute, initiateTestPayment };
  };


  const useCancelAutoCharge = () => {
    const [execute, result] = useMutation(CANCEL_AUTO_CHARGE);

    const cancelAutoCharge = ({ subscriptionId, onCompleted }: { subscriptionId: string, onCompleted?: VoidFunction }) => {

      if (!hasPermission("MAKE_SUBSCRIPTION_PAYMENT")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning'); 
      return execute({
        variables: { input: subscriptionId},
        onCompleted: (data) => {
          if (data) {
            onCompleted?.()
            showToast("Recurring Payments Cancelled", 'success')
          }
        },
      });
    }

    return { ...result, execute, cancelAutoCharge };
  };
  

  const useVerifyPayment = (value: string, skip?: boolean) => {
    const result = useQuery(VERIFY_PAYMENT, {
      errorPolicy: "all",
      skip,
      variables: { reference: value },
      pollInterval: 5
    });

    return { ...result };
  };


  const useConfirmAutoPayment = (invoiceId: string) => {
    const [show, setShow] = useState(false)
    const { initiatePayment, ...others } = useInitiatePayment()
    const { staff: { getStaff }} = useStaff()
    const isAutoCharge = getStaff?.facility?.organisation?.subscription?.isAutoCharge || false

    const confirmAutoPayment = () => {
    
      return (
        showAlert({
          children: (
            <Column gap={2}>
              <Column gap={1.5} width="100%">
                <Row justify='space-between'>
                  <H4 align="center" modStyles={{ my: 0 }}>
                    Heads Up!
                  </H4>
                  <Button 
                    width="min-content" 
                    variant="text" 
                    type="button" 
                    onClick={removeAlert}
                  >
                    <CancelIcon strokeWidth={0} />
                  </Button>
                </Row>
                <Text 
                  className="text-base" 
                  color="black" 
                  modStyles={{ my: 0 }}
                >
                  Do you want to enable Recurring Payments? {" "}
                  <Tooltip text="Choosing 'Yes' means your organization will never miss a payment with the autorenewal process.">
                    <InfoIcon width={12} height={12} />
                  </Tooltip>
                </Text>
                <Row>
                  <Checkbox
                    id="alert-checkbox" 
                    onChange={(e) => {
                      setShow(e.target.checked)
                    }}
                  />
                  <label htmlFor="alert-checkbox">
                    Don&apos;t show this again on this device
                  </label>
                </Row>
              </Column>
              <Row gap={1} justify="center" width="100%">
                <Button 
                  width="max-content"
                  variant="cancel"
                  color="black"
                  onClick={() => {
                    initiatePayment({ invoiceId, callbackUrl: `${window.location.href.split("#")?.[0]}#make-payment&id=${invoiceId}`, isAutoCharge: show  })
                    removeAlert()
                  }}
                > 
                  No
                </Button>
                <Button 
                  width="max-content"
                  onClick={() => {
                    initiatePayment({ invoiceId, callbackUrl: `${window.location.href.split("#")?.[0]}#make-payment&id=${invoiceId}`, isAutoCharge: show  })
                    removeAlert()
                  }}
                > 
                  Yes
                </Button>
              </Row>
            </Column>
          )
        })
      )
    }

    return { confirmAutoPayment, isAutoCharge, initiatePayment, ...others }
  }


  return {
    useGetBillingInvoices,
    useGetBillingInvoice,
    useInitiatePayment,
    useInitiateTestPayment,
    useVerifyPayment,
    useCancelAutoCharge,
    useConfirmAutoPayment
  }
}

export { useBilling };