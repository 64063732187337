import type React from "react"
import { useState, useRef, useEffect } from "react"
import { TooltipWrapper, TooltipTip } from "../styled"

interface TooltipProps {
  text: string
  children: React.ReactNode
}


const Tooltip: React.FC<TooltipProps> = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const triggerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)

  const handleMouseEnter = () => {
    setIsVisible(true)
  }

  const handleMouseLeave = () => {
    setIsVisible(false)
  }

  useEffect(() => {
    const updateTooltipPosition = () => {
      if (triggerRef.current && tooltipRef.current) {
        const triggerRect = triggerRef.current.getBoundingClientRect()
        const tooltipRect = tooltipRef.current.getBoundingClientRect()

        setPosition({
          top: -tooltipRect.height - 10,
          left: triggerRect.width / 2 - tooltipRect.width / 2,
        })
      }
    }

    if (isVisible) {
      updateTooltipPosition()
    }
  }, [isVisible])

  return (
    <TooltipWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={triggerRef}>
      {children}
      <TooltipTip ref={tooltipRef} $isVisible={isVisible} $position={position}>
        {text}
      </TooltipTip>
    </TooltipWrapper>
  )
}

export default Tooltip

