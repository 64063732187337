import {
  Avatar,
  Button,
  Checkbox,
  Column,
  H6,
  Pill,
  Row,
  Text,
} from 'src/components';
import {
  generateAvatar,
  openModal,
  removeModalHash,
  theme,
} from 'src/utils';
import {
  AddBookIcon,
  EventIcon,
  UpperRightArrow,
  VisibleIcon,
} from 'src/assets/icons';
import {
  useAppDispatch,
  updateJourneyStepActionItem,
  updateJourneyStepStatus,
  updatePastJourneyStepActionItem,
  updatePastJourneyStepStatus,
  useJourneyApis,
  updateStepStatus,
  updateStepActionItem,
  endPatientJourney,
  useTimeline,
  useSteps,
} from 'src/store';
import moment from 'moment';
import { useLocation } from 'wouter';
import { ParameterData, StepData } from 'src/types';
import { useStaff, useToast } from 'src/state';
import { useContext, useState } from 'react';
import { usePatientDetails } from 'src/pages/admin/PatientDetails/hooks/usePatientDetails';
import { PERMISSION_ACTION_MESSAGE } from 'src/constants';
import mixpanel from 'mixpanel-browser';
import { TagLink } from './styled';
import { getColor, getTagActionLink } from './helpers';
import { stepStatusMap } from './constants';
import { getCompletedActions } from './AllSteps';
import StaffAvatarGroup from './StaffAvatarGroup';
import SuccessModal from './SuccessModal';
import ViewWrapper from './ViewWrapper';
import { ViewContext } from '.';

export type StepViewType =
  | 'current-journey'
  | 'past-journey'
  | 'in-patients'
  | 'checkout';

interface StepType {
  id: string;
  step: StepData;
  journeyId?: string;
  viewType: StepViewType;
}

const getUrl = (journeyId?: string) => {
  if (journeyId) {
    return `#journey&id=${journeyId}&mView=allSteps`;
  }
  return '#journey&mView=allSteps';
};

const getParameterData = (param: ParameterData) => {
  const { text, staff, staffGroup } = param;
  if (!text && !staff && !staffGroup) return 'Nil';
  return (
    text ||
    staffGroup?.name ||
    `${param?.staff?.user?.firstName} ${param?.staff?.user?.lastName}`
  );
};

const Step: React.FC<StepType> = ({
  id: stepId,
  step,
  journeyId,
  viewType,
}) => {
  const [, setLocation] = useLocation();
  const { setView } = useContext(ViewContext);
  const patientId = step?.patient?.id as string;
  const dispatch = useAppDispatch();
  const [completeStepLoading, setCompleteStepLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [successType, setSuccessType] = useState<StepViewType>();
  const { fetchTimeline } = useTimeline(patientId);
  const [actionUpdating, setActionUpdating] = useState({
    arrIdx: -1,
    loading: false,
  });
  const { updatePatientStatus } = usePatientDetails(patientId);
  const { fetchAllSteps } = useSteps();
  const { hasPermission } = useStaff();
  const { showToast } = useToast();

  const { updateStep, updateStepActionItem: apolloUpdateActionItem } =
    useJourneyApis();

  
  const handleCompleteStep = () => {
    mixpanel.track(`Update Step Action Item`, { feature: 'Patient Journey' });
    if (!hasPermission('UPDATE_STEPS_IN_A_JOURNEY'))
      return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
    setCompleteStepLoading(true);
    updateStep({
      variables: {
        input: {
          status: 'COMPLETED',
        },
        stepId,
      },
      async onCompleted(d) {
        if (d) {
          if (step.stepTemplate.name === 'CHECKOUT') {
            try {
              await updatePatientStatus('CHECKED_OUT');
              await fetchAllSteps();
              dispatch(endPatientJourney({ patientId }));
              setCompleteStepLoading(false);
              setSuccessType('checkout');
              setIsCompleted(true);
              return;
            } catch {
              return;
            }
          }
          switch (viewType) {
            case 'past-journey':
              dispatch(
                updatePastJourneyStepStatus({
                  patientId,
                  journeyId: journeyId as string,
                  stepId,
                  status: 'COMPLETED',
                }),
              );
              break;
            case 'current-journey':
            case 'in-patients':
              dispatch(
                updateJourneyStepStatus({
                  patientId,
                  stepId,
                  status: 'COMPLETED',
                }),
              );
              dispatch(
                updateStepStatus({
                  stepId,
                  status: 'COMPLETED',
                }),
              );
              break;

            default:
              break;
          }

          setIsCompleted(true);
          setCompleteStepLoading(false);
          fetchTimeline();
        } else {
          setCompleteStepLoading(false);
          showToast('Unable to complete step!', 'error');
        }
      },
    });
  };

  const handleupdateJourneyStepActionItem = (arrIdx: number) => {
    mixpanel.track(`Update Step Action Item`, { feature: 'Patient Journey' });
    if (!hasPermission('UPDATE_STEPS_IN_A_JOURNEY'))
      return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
    setActionUpdating({ arrIdx, loading: true });
    if (step) {
      const { actions } = step;
      const action = actions[arrIdx];
      apolloUpdateActionItem({
        variables: {
          input: {
            isCompleted: !action.isCompleted,
          },
          stepActionItemId: action.id,
        },
        onCompleted(d) {
          if (d?.updateStepActionItem) {
            setActionUpdating({ arrIdx: -1, loading: false });
            switch (viewType) {
              case 'past-journey':
                dispatch(
                  updatePastJourneyStepActionItem({
                    patientId,
                    arrIdx,
                    journeyId: journeyId as string,
                    stepId,
                    checked: !step?.actions[arrIdx].isCompleted,
                    step: d?.updateStepActionItem?.stepActionItem?.step,
                  }),
                );
                break;
              case 'current-journey':
                dispatch(
                  updateJourneyStepActionItem({
                    patientId,
                    arrIdx,
                    stepId,
                    checked: !step?.actions[arrIdx].isCompleted,
                    step: d?.updateStepActionItem?.stepActionItem?.step,
                  }),
                );

                dispatch(
                  updateStepActionItem({
                    arrIdx,
                    stepId,
                    checked: !step?.actions[arrIdx].isCompleted,
                    step: d?.updateStepActionItem?.stepActionItem?.step,
                  }),
                );
                break;
              case 'in-patients':
                dispatch(
                  updateStepActionItem({
                    arrIdx,
                    stepId,
                    checked: !step?.actions[arrIdx].isCompleted,
                    step: d?.updateStepActionItem?.stepActionItem?.step,
                  }),
                );
                dispatch(
                  updateJourneyStepActionItem({
                    patientId,
                    arrIdx,
                    stepId,
                    checked: !step?.actions[arrIdx].isCompleted,
                    step: d?.updateStepActionItem?.stepActionItem?.step,
                  }),
                );
                break;
              default:
                break;
            }
            showToast('Action Updated Successfully', 'success');
          } else {
            setActionUpdating({ arrIdx: -1, loading: false });
            showToast('Error Updating Action', 'error');
          }
        },
      });
    }
  };

  const goBack = () => {
    if (viewType !== 'in-patients') {
      return () => {
        if (!hasPermission('VIEW_STEPS_IN_A_JOURNEY')) return undefined;
        setView('allSteps');
        setLocation(getUrl(journeyId));
      };
    }
    if (viewType === 'in-patients') {
      return () => {
        if (!hasPermission('VIEW_STEPS_IN_A_JOURNEY')) return undefined;
        openModal('step-info', stepId, `&patientId=${patientId}&view=allSteps`);
      };
    }
    return undefined;
  };

  return (
    <>
      {!isCompleted && (
        <ViewWrapper goBack={goBack()}>
          <Column minWidth="36vw">
            <Row justify="space-between">
              <H6 data-testid="step-title" modStyles={{ ma: 0 }}>
                {step?.stepTemplate?.name}
              </H6>
              <Pill
                data-testid="step-status"
                color={getColor(stepStatusMap[step?.status as string])}
                outlined
              >
                {stepStatusMap[step?.status as string]}
              </Pill>
            </Row>
            <Row align="center">
              <EventIcon />
              <Text
                size="sm"
                weight="semibold"
                color={theme.grey[700]}
                modStyles={{ ml: 0.3 }}
              >
                {`Last Updated: ${moment(step?.updatedAt).format(
                  'HH:mm, D MMM. YYYY',
                )}`}
              </Text>
            </Row>
            <Row
              width="100%"
              justify="space-between"
              gap={4}
              modStyles={{ mb: 1 }}
            >
              <Column minWidth="14.625rem">
                <Text size="sm" weight="semibold" color="#9B9B9B">
                  Patient
                </Text>
                <Row align="center" width="max-content" gap={0.25}>
                  <Avatar
                    size={30}
                    modStyles={{ px: 0, py: 0 }}
                    src={generateAvatar(
                      `${step?.patient.user.firstName} ${step?.patient.user.lastName}`,
                    )}
                  />
                  <Text
                    size="sm"
                    weight="bold"
                    color={theme.primary[700]}
                    modStyles={{ ml: 0.3 }}
                  >
                    {`${step?.patient.user.firstName} ${step?.patient.user.lastName}`}
                  </Text>
                  {viewType === 'in-patients' && (
                    <Button
                      width="max-content"
                      background={theme.grey[100]}
                      color={theme.primary[700]}
                      modStyles={{ py: 0.25, px: 0.625 }}
                      onClick={() => {
                        mixpanel.track(`Click 'See Patient' button`, {
                          feature: 'Patient Journey',
                        });
                        removeModalHash();
                        setLocation(`/admin/patients/${patientId}`);
                      }}
                    >
                      <VisibleIcon />
                      See Patient
                    </Button>
                  )}
                </Row>
              </Column>
              {!!step?.eventLogs?.length && (
                <Column minWidth="17.625rem" width="max-content">
                  <Text size="sm" weight="semibold" color="#9B9B9B">
                    Updates
                  </Text>
                  <StaffAvatarGroup
                    events={step?.eventLogs?.map((log) => ({
                      staff: log.staff,
                      updatedAt: log.updatedAt,
                    }))}
                  />
                </Column>
              )}
            </Row>
            {!!step?.parameters.length &&
              step?.parameters.map((param, idx) => (
                <Column
                  key={idx}
                  modStyles={{
                    bg: theme.grey[50],
                    br: 0.375,
                    pa: 0.625,
                    mb: 1,
                  }}
                >
                  <Text
                    size="sm"
                    weight="semibold"
                    color="#9B9B9B"
                    modStyles={{ mb: 0.5 }}
                  >
                    {param.description}
                  </Text>
                  <Text
                    size="sm"
                    weight="semibold"
                    color={theme.primary[700]}
                    modStyles={{ mt: 0 }}
                  >
                    {getParameterData(param)}
                  </Text>
                </Column>
              ))}
            {!!step?.actions?.length && (
              <Column
                gap={1}
                modStyles={{ bg: theme.grey[50], pa: 0.625, mb: 1 }}
              >
                {step?.actions.map((action, idx) => (
                  <Row
                    key={idx}
                    justify="space-between"
                    align="center"
                    onClick={() => {
                      if (step.status === 'COMPLETED')
                        showToast('cannot update a completed step', 'error');
                    }}
                  >
                    <Checkbox
                      data-testid="action-item"
                      isLoading={
                        actionUpdating.arrIdx === idx && actionUpdating.loading
                      }
                      activeColor="green"
                      label={action.description}
                      checked={action.isCompleted}
                      disabled={
                        actionUpdating.loading || step.status === 'COMPLETED'
                      }
                      onChange={() => handleupdateJourneyStepActionItem(idx)}
                    />
                    {action.tag !== 'Manual Action' && (
                      <TagLink
                        onClick={(e) => {
                          mixpanel.track(`Clicked 'Tag Action' link`, {
                            feature: 'Patient Journey',
                          });
                          e.stopPropagation();
                          removeModalHash();
                          setLocation(getTagActionLink(patientId)[action.tag]);
                        }}
                      >
                        <UpperRightArrow />
                      </TagLink>
                    )}
                  </Row>
                ))}
              </Column>
            )}
            <Column width="100%" gap={0.62} modStyles={{ mt: 1 }}>
              {hasPermission('ADD_RECORD_TO_PATIENT_PROFILE') && (
                <Button
                  width="max-content"
                  background={theme.secondary[100]}
                  color={theme.secondary[700]}
                  onClick={() => {
                    mixpanel.track(`Click 'Add Record' button`, {
                      feature: 'Patient Journey',
                    });
                    removeModalHash();
                    if (viewType === 'in-patients') {
                      return setLocation(
                        `/admin/patients/${patientId}?tab=timeline&view=forms`,
                      );
                    }
                    setLocation(`?tab=timeline&view=forms`);
                  }}
                  modStyles={{ mb: 1 }}
                >
                  <AddBookIcon color={theme.secondary[700]} />
                  Add Record
                </Button>
              )}
              <Row justify="space-between" gap={1}>
                <Button
                  width="max-content"
                  onClick={() => removeModalHash()}
                  background="neutral"
                  color="black"
                >
                  Cancel
                </Button>
                <Button
                  data-testid="complete-step"
                  background="primary"
                  width="max-content"
                  isLoading={completeStepLoading}
                  disabled={
                    (!!step?.actions?.length &&
                      getCompletedActions(step) !== step?.actions.length) ||
                    actionUpdating.loading ||
                    step?.status === 'COMPLETED'
                  }
                  onClick={handleCompleteStep}
                >
                  Complete Step
                </Button>
              </Row>
            </Column>
          </Column>
        </ViewWrapper>
      )}
      {isCompleted && (
        <SuccessModal
          journeyId={journeyId}
          stepName={step?.stepTemplate?.name}
          viewType={successType || viewType}
        />
      )}
    </>
  );
};
export default Step;
