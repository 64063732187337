import CSVValidator, {
  bulkInventoryValidators,
  bulkPatientsValidators,
} from '@afuadajo/csv-validator';
import { useState, useRef } from 'react';
import { getModalParams } from 'src/utils';
import { Data, Errors, Validation } from '@afuadajo/csv-validator/build/types';
import { ADD_BULK_PATIENTS } from 'src/constants';
import { useMutation } from '@apollo/client';
import { openReader } from '../utils';

type UploadType = 'inventory' | 'patients';
type View = 'landing' | 'dropzone' | 'preview' | 'success' | 'error';
type RowConfig = {
  skip?: number;
  transform?: (val: string) => string;
  validation: Record<string, Validation>;
};

const rowConfig: Record<UploadType, RowConfig> = {
  inventory: {
    transform(val) {
      return val.split('(')[0].trim();
    },
    validation: bulkInventoryValidators,
  },
  patients: {
    validation: bulkPatientsValidators,
    transform(val) {
      return val.split('(MM/DD/YYYY)')[0].trim();
    },
  },
};

const useBulkApis = (modalView: UploadType) => {
  const [
    saveProducts,
    { error: saveProductsError, loading: saveProductsLoading },
  ] = useMutation(ADD_BULK_PATIENTS, { errorPolicy: 'all' });
  const [
    savePatients,
    { error: savePatientsError, loading: savePatientsLoading },
  ] = useMutation(ADD_BULK_PATIENTS, { errorPolicy: 'all' });

  const operations = {
    patients: {
      saveBulk: savePatients,
      saveBulkError: savePatientsError,
      saveBulkLoading: savePatientsLoading,
    },
    inventory: {
      saveBulk: saveProducts,
      saveBulkError: saveProductsError,
      saveBulkLoading: saveProductsLoading,
    },
  };
  return operations[modalView];
};

export const useBulkUpload = () => {
  const modalView = getModalParams('for') as UploadType;
  const [view, setView] = useState<View>('landing');
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [mockLoading, setMockLoading] = useState(false);
  const [result, setResult] = useState<{ data: Data; errors: Errors } | null>(
    null,
  );
  const [page, setPage] = useState(1);
  const operations = useBulkApis(modalView);

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    setError(false);
  };

  const parser = new CSVValidator(rowConfig[modalView]?.validation);
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files?.[0] as File;
    if (!isFileValid(newFile)) {
      setError(true);
      if (inputRef.current) {
        inputRef.current.value = '';
        setFile(null);
      }
      return;
    }
    setMockLoading(true);
    openReader(newFile, {
      onLoad: (res) => {
        const data = parser.parse(res, {
          header: true,
          skipFirstNLines: rowConfig[modalView]?.skip || 0,
          transformHeader:
            rowConfig[modalView]?.transform || ((header) => header),
        });
        setResult(data);
        setFile(newFile);
        setMockLoading(false);
        setView('preview');
      },
      onError: () => {
        setError(true);
        setProgress(0);
      },
      onProgress: (event) => {
        if (event.lengthComputable) {
          const percent = Math.round((event.loaded / event.total) * 100);
          setProgress(percent);
        }
      },
    });
  };

  const onDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    const files = e?.dataTransfer?.files;
    const newFile = files?.[0] as File;
    if (!isFileValid(newFile)) {
      setError(true);
      if (inputRef.current) {
        inputRef.current.value = '';
        setFile(null);
      }
      setIsDragging(false);
      return;
    }
    openReader(newFile, {
      onLoad: (res) => {
        const data = parser.parse(res, {
          header: true,
          skipFirstNLines: rowConfig[modalView]?.skip || 0,
          transformHeader:
            rowConfig[modalView]?.transform || ((header) => header),
        });
        setFile(newFile);
        setResult(data);
        setMockLoading(false);
        setIsDragging(false);
        setView('preview');
      },
      onError: () => {
        setError(true);
        setProgress(0);
        setIsDragging(false);
      },
      onProgress: (event) => {
        if (event.lengthComputable) {
          const percent = Math.round((event.loaded / event.total) * 100);
          setProgress(percent);
        }
      },
    });
  };

  const getFileSizeString = (fileSize: number) => {
    const sizeInKB = fileSize / 1024;
    const sizeInMB = sizeInKB / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    }
    return `${sizeInKB.toFixed(2)} KB`;
  };

  const isFileValid = (obj: File) => {
    const fileSize = obj?.size;

    if (fileSize > 5 * 1024 * 1024 || !obj.name.endsWith('.csv')) {
      return false;
    }
    return true;
  };

  const resetInput = () => {
    setResult(null);
    setFile(null);
    setPage(1);
    setView('dropzone');
  };
  return {
    view,
    file,
    setFile,
    modalView,
    setView,
    inputRef,
    isDragging,
    error,
    progress,
    mockLoading,
    result,
    page,
    setPage,
    onDragOver,
    onDragLeave,
    onChange,
    onDrop,
    getFileSizeString,
    resetInput,
    rowConfig,
    operations,
  };
};
