/* eslint-disable @typescript-eslint/no-shadow */
import { currencyformatter } from 'src/utils';
import {
  TR,
  TD,
  TableContent,
} from 'src/pages/admin/PatientDetails/Invoice/styled';
import { Column } from 'src/components';
import { useRowData } from 'src/state';
import { BillingInvoice } from 'src/types';


const tableHeaders = [
  'Plan Name',
  'Billing Period',
  'Amount',
];


const SummaryTable = () => {

  const { getDataByKey } = useRowData();
  const billingInvoice = getDataByKey("billing") as BillingInvoice;
  const { subscription, amount, period, outstanding, netAmount } = billingInvoice || {}

  
  const invoice = [{
    planName: subscription?.name || "",
    billingPeriod: period || "",
    amount: amount || 0
  }, {
    planName: '',
    billingPeriod: outstanding?.period || '',
    amount: outstanding?.amount || 0
  }];

  const invoiceSummary = outstanding?.period ? invoice : invoice?.slice(0, -1)
  const totals = [
    {
      summary: 'Amount Due',
      col2: '',
      total: netAmount,
    },
    {
      summary: 'Sub-Total',
      col2: '',
      total: netAmount,
    },
  ];



  return (
    <TableContent>
      <Column gap={0.3}>
        <table>
          <thead>
            <TR>
              {tableHeaders.map((title, i) => (
                <th key={i}>{title}</th>
              ))}
            </TR>
          </thead>
          <tbody>
            {invoiceSummary?.map(({ planName, billingPeriod, amount }, i) => (
              <TR key={i}>
                <TD>{planName}</TD>
                <TD>{billingPeriod}</TD>
                <TD>
                  {currencyformatter.format(
                    parseInt(Number(amount).toString(), 10),
                  )}
                </TD>
              </TR>
            ))}
            {totals?.map(({ summary, total }, i) => {
              return (
                <TR key={i} noBorder>
                  <TD colSpan={2} size="base" weight="bold">
                    {summary}
                  </TD>
                  <TD size="base" weight="bold">
                    {currencyformatter.format(Number(total) ?? 0)}
                  </TD>
                </TR>
              );
            })}
          </tbody>
        </table>
      </Column>
    </TableContent>
  );
};

export default SummaryTable;
