import { gql } from '@apollo/client';

export const INITIATE_PAYMENT = gql`
  mutation InitiatePayment($input: InitiatePaymentInput) {
    initiatePayment(input: $input) {
      data
      message
    }
  }
`;

export const INITIATE_TEST_PAYMENT = gql`
  mutation InitiateTestPayment($input: InitiatePaymentInput) {
    initiateTestPayment(input: $input) {
      data
      message
    }
  }
`;

