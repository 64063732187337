import { ClockIcon } from 'src/assets/icons';
import {
  Button,
  Checkbox,
  Column,
  DotIndicator,
  IconButton,
  Paragraph,
  Row,
  Text,
  Card,
} from 'src/components';
import { theme, font } from 'src/utils';
import styled, { css } from 'styled-components';
import { DatePickerWrapper } from 'src/components/DatePicker/styled';
import { DropdownTrigger } from 'src/components/Dropdown/styled';
import { View } from 'react-big-calendar';

export const Section = styled(Column)<{
  flex?: string | number;
}>`
  overflow-y: auto;
  max-height: inherit;
  flex: ${(props) => props.flex || `none`};
`;

export const PageWrapper = styled(Column)`
  padding-top: 1rem;
  @media (max-width: 960px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const DoctorHeading = styled(Row)`
  background: ${theme.grey[100]};
  padding: 0.5rem 1rem;
  flex: 1;
  align-items: center;
  align-self: center;
  width: 100%;
  justify-content: flex-start;
  border-radius: 0.625rem;
  img {
    padding: 0;
    @media (max-width: 960px) {
      width: 35px;
      height: 35px;
      min-width: 35px;
    }
  }
  span {
    @media (max-width: 960px) {
      font-size: ${font.sizes.xs};
    }
  }
`;

export const CalendarText = styled(Text)`
  margin: 0;
  text-overflow: clip;
  white-space: nowrap;
  @media (max-width: 960px) {
    font-size: ${font.sizes.xs};
  }
`;

export const DateHeaderRow = styled(Row)<{
  isToday?: boolean;
}>`
  background-color: ${(props) =>
    props.isToday ? theme.black[100] : theme.grey[100]};
  padding: 0.75rem;
  border-radius: 0.625rem;
  height: 100%;
  min-width: 6.6875rem;
`;

export const EventWrapper = styled(Row)`
  align-items: center;
  span {
    @media (max-width: 960px) {
      font-size: ${font.sizes.xxs};
    }
  }
  & > div {
    width: max-content;
    height: max-content;
    align-items: center;
  }
  svg,
  path {
    stroke-width: 0;
    max-width: 0.75rem;
    max-height: 0.75rem;
    @media (max-width: 960px) {
      max-width: 0.5rem;
      max-height: 0.5rem;
    }
  }
`;

export const CalendarViewSwitch = styled(Row)<{ view: View }>`
  background: ${theme.grey[100]};
  width: max-content;
  border-radius: 0.625rem;
  padding: 0.3rem;
  ${({ view }) => {
    switch (view) {
      case 'week':
        return css`
          > :last-child {
            background: ${theme.white[100]};
            color: black;
          }
        `;
      case 'day':
        return css`
          > :first-child {
            background: ${theme.white[100]};
            color: black;
          }
        `;
      default:
        return css`
          > * {
            background: transparent;
          }
        `;
    }
  }};
  * {
    background: transparent;
    color: #9b9b9b;
    font-size: ${font.sizes.xs};
    width: 4.75rem;
    height: 2.3125rem;
  }
`;

export const WeeklyDateRow = styled(Row)`
  button {
    background: ${theme.grey[700]};
    width: 3.7rem;
    white-space: nowrap;
    padding: 0.5rem;
    font-weight: ${font.weights.semibold};
    font-size: ${font.sizes.xs};
    color: ${theme.white[100]};
  }
  .disabled {
    background: ${theme.grey[50]};
    color: ${theme.grey[500]};
  }
`;

export const FixedTabs = styled(Row)`
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: ${theme.white[100]};
  div {
    justify-content: center;
    gap: 2rem;
    @media (max-width: 960px) {
      flex: 1;
    }
  }
  ${Row} {
    justify-content: center;
    gap: 0.5rem;
    svg,
    path {
      fill: currentColor;
      stroke-width: 0;
    }
  }
`;

export const FixedHeader = styled(Row)`
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const AddTodoButton = styled(IconButton)`
  min-width: 2.75rem;
  min-height: 2.75rem;
  svg,
  path {
    width: 1.375rem;
    height: 1.375rem;
    fill: ${theme.white[100]};
  }
`;

export const TodoCheckbox = styled(Checkbox)`
  margin: 0;
  min-width: 1rem;
  min-height: 1rem;
`;

export const TodoText = styled(Text)`
  margin: 0;
`;

export const TodoActionButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${theme.grey[100]};
  svg,
  path {
    width: 0.75rem;
    heigth: 0.75rem;
    stroke-width: 0.15;
    fill: ${theme.grey[300]};
  }
`;

export const DueTimeIcon = styled(ClockIcon)`
  svg,
  path {
    width: 0.75rem;
    height: 0.75rem;
    stroke-width: 0.15;
    fill: ${theme.grey[600]};
  }
`;

export const NoWrapParagraph = styled(Paragraph)`
  text-align: center;
  white-space: nowrap;
  @media (max-width: 960px) {
    font-size: ${font.sizes.sm};
  }
`;

export const WeeklyDateButton = styled(Button)`
  width: 3.75rem;
`;

export const CalendarTabContent = styled(Column)`
  padding: 1rem;
  @media (max-width: 960px) {
    padding: 0;
  }
  input {
    /* border-radius: 0.625rem; */
    font-weight: ${font.weights.normal};
    font-size: ${font.sizes.base};
    background-color: ${theme.primary[100]};
    &::placeholder {
      color: ${theme.primary[400]};
      font-weight: ${font.weights.normal};
      @media (max-width: 960px) {
        font-size: ${font.sizes.sm};
      }
    }
    @media (max-width: 960px) {
      font-size: ${font.sizes.sm};
    }
  }
`;

export const EventIndicator = styled(DotIndicator)`
  min-width: 0.375rem;
  min-height: 0.375rem;
`;

export const PaddingContainer = styled(Column)`
  padding: 0.25rem;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 15rem);
  height: calc(100vh - 15rem);
  @media (max-width: 960px) {
    max-height: calc(100vh - 17rem);
  }
  img {
    padding: 0;
  }
  ${Card} {
    cursor: pointer;
  }
`;

export const StyledContainer = styled(Column)<{
  isDoctorAndWeek: boolean;
}>`
  position: relative;
  width: 100%;
  .rbc-calendar {
    width: 100%;
    height: calc(100vh - 7rem);

    .rbc-current-time-indicator {
      background-color: #f86b3f;
    }

    .rbc-current-time-indicator::before {
      // display: block;
      // position: absolute;
      // left: -3px;
      // top: -3px;
      // content: " ";
      // color: white;
      // background-color: #f86b3f;
      // border-radius: 50%;
      // width: 58px;
      // height: 8px;

      // content: "10:00am";
      // position: absolute;
      // top: -11px;
      // left: -42px;
      // height: 24px;
      // width: 42px;
      // padding: 1px 4px;
      // border: 2px solid ${theme.secondary[700]};
      // border-top-right-radius: 35%;
      // border-bottom-right-radius: 35%;
      // font-size: 12px;
      // word-wrap: normal;
      // font-weight: bold;
      // color: white;
      // background: ${theme.secondary[700]};
      // box-shadow: 0px 0px 6px 1px white;
    }

    .rbc-time-view {
      .rbc-day-slot {
        min-width: 11.25rem;
        @media (max-width: 960px) {
          min-width: 9.5rem;
        }
      }
    }

    .rbc-time-view {
      .rbc-time-content {
        width: ${(props) => (props.isDoctorAndWeek ? `max-content` : `100%`)};
      }
    }

    @media (max-width: 960px) {
      height: calc(100vh - 15.5rem);
    }
  }
`;

export const MobileTabs = styled(Row)`
  & > div {
    justify-content: center;
    gap: 1;
  }
  ${Row} {
    gap: 0.25rem;
    svg,
    path {
      fill: currentColor;
      stroke-width: 0;
    }
  }
`;

export const VisibilityContainer = styled(Row)<{
  show: boolean;
}>`
  display: ${(props) => (props.show ? `flex` : `none`)};
`;

export const FloatingButton = styled(IconButton)`
  border-radius: 50%;
  position: absolute;
  bottom: 2rem;
  right: 3rem;
  z-index: 10;
  @media (max-width: 960px) {
    right: 1.5rem;
  }
`;

export const DateRangeWrapper = styled(DatePickerWrapper)`
  left: 0;
  width: max-content;
`;

export const DateRangeTrigger = styled(DropdownTrigger)`
  padding: 0.25rem 1rem;
  justify-content: center;
  height: 2.3125rem;
  min-width: 13rem;
  svg,
  path {
    fill: ${theme.white[100]};
  }
  @media (max-width: 960px) {
    min-width: 11.375rem;
  }
`;

export const ErrorText = styled(Text)`
  padding: 0 1rem;
`;

export const CalendarIconButton = styled(IconButton)`
  min-width: 2.3125rem;
  min-height: 2.3125rem;
`;
