import _ from 'lodash';
import { LeftArrow, RightArrow } from 'src/assets/icons';
import { Text, Row, Column } from 'src/components';
import { Permissions, TableProps } from 'src/types';
import { openModal, theme } from 'src/utils';
import { useRowData, useStaff, useToast } from 'src/state';
import { useLocation } from 'wouter';
import { PERMISSION_ACTION_MESSAGE } from 'src/constants';
import useMediaQuery from 'src/utils/useMediaQuery';
import ReactTooltip from 'react-tooltip';
import {
  PageButtons,
  Pages,
  PaginationWrapper,
  TableContainer,
  TableContent,
  TableRow,
} from './styled';

export const Table: React.FC<TableProps> = ({
  data,
  columns,
  href,
  color,
  dataKey,
  pagination = {
    page: 1,
    size: 10,
    total: 10,
  },
  handleGoToPage,
  handleRowClick,
  hasTooltip,
  ...props
}) => {
  const [, setLocation] = useLocation();
  const { updateRowData } = useRowData();
  const { hasPermission } = useStaff();
  const { showToast } = useToast();
  const isMobile = !useMediaQuery('(min-width: 960px)');
  const entriesPerPage = 10;
  const roundedTotal = Math.ceil(
    Number(pagination.total) / entriesPerPage || 0,
  );
  const startEntry = (pagination.page - 1) * entriesPerPage + 1;
  const endEntry = Math.min(pagination.page * entriesPerPage, pagination.total);
  const displayText =
    pagination.total === 0
      ? `No entries to display`
      : `Showing ${startEntry} to ${endEntry}  of ${pagination.total} entries`;

  return (
    <TableContainer>
      <TableContent {...props}>
        <table>
          <thead>
            <tr>
              {columns.map(({ title }, i) => (
                <th key={`${title}-${i}`}>{title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 &&
              !props.isLoading &&
              data.map((row, i) => {
                const val = href ? _.get(row, href.dataIndex, '') : '';
                const path = href?.render ? href.render(val, row) : '';
                const hashValue = path.split('#');

                const handleClick = () => {
                  if (
                    href?.permission &&
                    !hasPermission(href?.permission as Permissions)
                  ) {
                    return showToast(PERMISSION_ACTION_MESSAGE, 'warning');
                  }
                  setLocation(hashValue[0]);
                  if (dataKey) updateRowData(dataKey, data[i]);
                  if (hashValue.length > 1) return openModal(hashValue[1]);
                };

                return (
                  <TableRow
                    key={i}
                    hasLink={Boolean(val)}
                    color={color?.[i]}
                    data-tip
                    data-for={`tooltip-${i}`}
                    errored={row?.errored as boolean}
                    onClick={
                      handleRowClick ? () => handleRowClick(row) : handleClick
                    }
                    data-testid="table-row"
                    {...props}
                  >
                    {columns.map(({ dataIndex, render }, j) => {
                      const value = _.get(row, dataIndex, '');
                      const subRowValue = _.get(
                        (
                          row?.subRow as unknown as Array<
                            Record<string, unknown>[]
                          >
                        )?.[0],
                        dataIndex,
                        '',
                      );
                      return (
                        <td key={j}>
                          {render ? render(value, row) : `${value}`}{' '}
                          {subRowValue && (
                            <Text
                              weight="bold"
                              size="xs"
                              color={theme.green[500]}
                            >
                              {subRowValue}
                            </Text>
                          )}
                        </td>
                      );
                    })}
                  </TableRow>
                );
              })}
            {props.isLoading && (
              <tr data-testid="table-loader">
                <td colSpan={columns.length}>
                  <Row width="100%" justify="center">
                    <Text size="sm">Loading...</Text>
                  </Row>
                </td>
              </tr>
            )}
            {data?.length < 1 && !props.isLoading && (
              <tr data-testid="table-empty">
                <td colSpan={columns.length}>
                  <Row width="100%" justify="center">
                    <Text size="sm">No Data</Text>
                  </Row>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {hasTooltip &&
          data.map(
            (row, i) =>
              row?.tooltipValue && (
                <ReactTooltip id={`tooltip-${i}`} key={i} place="top">
                  {typeof row?.tooltipValue === 'function' ? (
                    row?.tooltipValue()
                  ) : (
                    <Column>
                      {(row?.tooltipValue as string)
                        ?.split?.(',')
                        ?.map((value, index) => (
                          <Text key={index} size="sm" color="#ffffff">
                            {value}
                          </Text>
                        ))}
                    </Column>
                  )}
                </ReactTooltip>
              ),
          )}
      </TableContent>
      {!props.isLoading && !props.hidePagination && (
        <PaginationWrapper>
          {!isMobile ? (
            displayText
          ) : (
            <Row align="center" gap={1}>
              Show Results: {'   '}
              <PageButtons variant="text" isPrevOrNext>
                {pagination.size}
              </PageButtons>
            </Row>
          )}
          <Pages>
            <PageButtons
              onClick={() => handleGoToPage?.(pagination.page - 1)}
              disabled={pagination.page === 1}
              isPrevOrNext
              variant="text"
              color="secondary"
            >
              <LeftArrow />
            </PageButtons>
            {[...Array(roundedTotal)].map((page, index) => {
              const pageNumber = index + 1;
              const isCurrentPage = pageNumber === pagination.page;
              const shouldRender =
                Math.abs(pageNumber - pagination.page) <= 2 ||
                pageNumber === 1 ||
                pageNumber === roundedTotal;
              const renderEllipsis =
                Math.abs(pageNumber - pagination.page) === 3 &&
                pageNumber !== 1 &&
                pageNumber !== roundedTotal;

              if (renderEllipsis) {
                return (
                  <PageButtons variant="text" color="grey" key={index}>
                    ...
                  </PageButtons>
                );
              }

              if (!shouldRender) {
                return null;
              }

              return (
                <PageButtons
                  onClick={() => handleGoToPage?.(pageNumber)}
                  variant={isCurrentPage ? 'solid' : 'text'}
                  background={isCurrentPage ? 'secondary' : 'grey'}
                  key={index}
                >
                  {pageNumber}
                </PageButtons>
              );
            })}
            <PageButtons
              isPrevOrNext
              variant="text"
              background="secondary"
              onClick={() => handleGoToPage?.(pagination.page + 1)}
              disabled={pagination.page === roundedTotal || roundedTotal === 0}
            >
              <RightArrow />
            </PageButtons>
          </Pages>
        </PaginationWrapper>
      )}
    </TableContainer>
  );
};
