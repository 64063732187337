import React, { SyntheticEvent } from "react";
import usePortal from "react-useportal";
import { DropdownContainer } from "src/components/Dropdown/styled";

type CustomEvent = {
  event?: SyntheticEvent<unknown, Event>
  portal: React.RefObject<HTMLElement>
  targetEl: React.RefObject<HTMLElement>
} & SyntheticEvent<unknown, Event>

interface UseDropdownConfig {
	width?: number | string;
	height?: number;
	onOpen?: (args: CustomEvent) => void; 
}

interface UseDropdownReturn {
	Dropdown: React.FC<React.HTMLAttributes<HTMLDivElement>>
	toggleDropdown: () => void;
	isOpen: boolean;
}

export const useDropdown = ({ width, height, onOpen, ...config }: UseDropdownConfig = {}): UseDropdownReturn => {

	const { isOpen, togglePortal, Portal } = usePortal({
    onOpen(args) {
      const { portal, targetEl } = args;
      const clickedEl = targetEl.current;
      const { top, bottom, left, right } = clickedEl.getBoundingClientRect();
      let l = left;
      let t = top + clickedEl.clientHeight + 10;
      const outRight = window.innerWidth < left + clickedEl.offsetWidth;
      const outBottom =
        window.innerHeight < top + portal.current.clientHeight;
      // const outTop = false;
      // const outLeft = false;
      if (outRight) {
        l = window.innerWidth - (right - left + clickedEl.offsetWidth);
      } 
      // if (outLeft) {
      // }
      if (outBottom) {
        t = window.innerHeight - (bottom - top + (height || 0));
      } 
      // if (outTop) {
      // }
      portal.current.style.cssText = `
      width: ${width || `max-content`};
      position: absolute;
      top: ${t}px;
      left: ${l - 50}px;
      background: #ffff;
    `;
      if (onOpen) onOpen(args);
    },
    ...config
	});



	return {
    Dropdown: (props: React.HTMLAttributes<HTMLDivElement>) => {
      return (
        <Portal>
          <DropdownContainer {...props} />
        </Portal>
		  )
    },
		toggleDropdown: togglePortal,
		isOpen
	};
};
