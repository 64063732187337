import { gql } from '@apollo/client';

export const GET_BILLING_INVOICES = gql`
  query Invoices($input: GetBillingInvoicesInput) {
    getBillingInvoices(input: $input) {
      total
      invoices {
        id: _id
        amount
        invoiceId
        period
        paidAt
        status
        createdAt
        updatedAt
        dueDate
        netAmount
        netPeriod
        payment {
          provider
          reference
        }
        subscription {
          billingFrequency
          freeTrialEnd
          isFreeTrial
          isGracePeriod
          name
          nextBillingDate
          price
          status
          period
          features
        }
        outstanding {
          amount
          period
        }
        staff {
          user {
            lastName
            firstName
            emails
          }
        }
      }
    }
  }
`;


export const GET_BILLING_INVOICE = gql`
  query GetBillingInvoice($input: ID!) {
    getBillingInvoice(input: $input) {
      id: _id
      amount
      invoiceId
      period
      paidAt
      status
      createdAt
      updatedAt
      dueDate
      netAmount
      netPeriod
      payment {
        provider
        reference
      }
      subscription {
        isAutoCharge
        billingFrequency
        freeTrialEnd
        isFreeTrial
        isGracePeriod
        name
        nextBillingDate
        price
        status
        period
        features
      }
      outstanding {
        amount
        period
      }
      staff {
        user {
          lastName
          firstName
          emails
        }
      }
    }
  }
`;