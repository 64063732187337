import {
  CircleFileIcon,
  CircleCheckIcon,
  CircleUncheckedIcon,
} from 'src/assets/icons';
import { theme } from 'src/utils';
import { Text } from '../../components/Typography';
import { Row, Column } from '../../components/Flex';

interface LoaderProps {
  progress: number;
  filename: string;
  fileSize: string;
}

export const Loader: React.FC<LoaderProps> = ({
  progress,
  fileSize,
  filename,
}) => {
  return (
    <Row
      modStyles={{
        pa: 1,
        br: '8px',
        border: `1px solid ${theme.secondary[700]}`,
      }}
    >
      <CircleFileIcon />
      <Column gap={0.2} modStyles={{ ml: 1 }}>
        <Row>
          <Text
            size="sm"
            weight="semibold"
            color={theme.black[100]}
            width="100%"
            modStyles={{ ma: 0 }}
          >
            {filename}
          </Text>
          {progress === 100 ? <CircleCheckIcon /> : <CircleUncheckedIcon />}
        </Row>
        <Text size="sm" modStyles={{ ma: 0 }}>
          {fileSize}
        </Text>
        <Row align="center" justify="space-between" width="100%">
          <div
            style={{
              width: '100%',
              height: '0.5rem',
              backgroundColor: theme.grey[100],
              borderRadius: '4px',
              overflow: 'hidden',
              marginRight: '0.5rem',
            }}
          >
            <div
              style={{
                width: `${progress}%`,
                height: '100%',
                backgroundColor: theme.secondary[700],
              }}
            />
          </div>
          <Text size="sm" weight="semibold" color={theme.black[100]}>
            {progress}%
          </Text>
        </Row>
      </Column>
    </Row>
  );
};
