import { Chip, Column, Row } from 'src/components';
import { font, theme } from 'src/utils/variables';
import styled, { keyframes } from 'styled-components';


export const UpgradeChip = styled(Chip)`
  cursor: pointer;
  background-color: ${theme.secondary[600]};
  color: white;
  font-size: ${font.sizes.base};
  border-radius: 999px;
  border: 0;
  font-weight: 500;
  paddding: 2px 10px;
`;

export const BorderColumn = styled(Column)`
  border: 1px solid ${theme.grey[200]};
  border-radius: 6px;
  border-bottom: 10px solid ${theme.accent[700]};
  table > thead > tr > th:first-child {
    padding-left: 30px;
  }
  table > thead > tr > th:last-child {
    padding-right: 30px;
  }
  table > tbody > tr > td:last-of-type {
    padding-right: 30px;
  }
  table > tbody > tr > td:first-of-type {
    padding-left: 30px;
  }
  & > div > div > div > span {
    font-size: 12px;
  }
  table > thead > tr > th {
    font-size: 12px;
  }
  table > tbody > tr > td {
    font-size: 13px;
  }
`

export const TableActionButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`

export const Grid = styled.div<{
  rows?: number
  gap?: number
}>`
  display: grid;
  grid-template-columns: repeat(${({ rows }) => rows || 4}, minmax(0, 1fr));
  gap: ${({ gap }) => `${gap}rem` || `1rem` };
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
  height: auto;
  cursor: pointer;
`



export const RadioCardItem = styled.input.attrs({ 
  type: 'radio',
  className: 'radio-card-input',
})`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
`;

export const RadioCardLabel = styled.label.attrs({
  className: 'radio-card-label',
})`
  padding: 1rem;
  border: 1px solid ${theme.grey[700]};
  border-radius: 8px;
  transition: all 0.2s ease;
  cursor: pointer;
  min-width: 200px;
  width: 100%;
  display: flex;
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;

  &:hover {
    border-color: ${theme.grey[200]};
  }


  &:focus-within {
    box-shadow: 0 0 0 2px ${theme.secondary[100]};
  }


  &:has(input.radio-card-input:checked) {
    border-color: ${theme.secondary[700]};
  }
  
  &:has(input.radio-card-input:checked) > div > ul > li:before {
    color: ${theme.secondary[700]};
  }

  input.radio-card-input:checked ~ ${Row} {
    // path {
    //   fill: ${theme.secondary[700]};
    // }
    z-index: 10;
    position: absolute;
    top: 12px;
    right: -8px;
  }

  &:has(input.radio-card-input:disabled) {
  &:has(input.radio-card-input:disabled) {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #f5f5f5;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 30px;
  position: relative;

  &::before {
    content: '✔';
    position: absolute;
    left: 10px;
    color: black;
  }
`;


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`

export const TooltipTip = styled.div<{ $isVisible: boolean; $position: { top: number; left: number } }>`
  position: absolute;
  border-radius: 10px;
  left: ${(props) => props.$position.left}px;
  top: ${(props) => props.$position.top}px;
  background: ${theme.grey[100]};
  border: 1px solid ${theme.grey[200]};
  color: ${theme.grey[700]};
  padding: 6px;
  font-size: 12px;
  line-height: 2rem;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  width: max-content;
  text-align: left;
  max-width: 35ch;
  animation: ${fadeIn} 0.3s ease-in-out;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  visibility: ${(props) => (props.$isVisible ? "visible" : "hidden")};
`

export const ColumnWrap = styled(Column)`
  @media (max-width: 600px) {
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    & > div {
      flex: 1;
      width: 100%;
      flex-shrink: 0;
      display: inline-flex;
    }
  }
`;