export const handleDownload = (fileUrl: string, name?: string) => {
  return new Promise<void>((resolve) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `${name || 'file.csv'}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    resolve();
  });
};
