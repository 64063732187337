import { Button, Card } from 'src/components';
import { font, theme } from 'src/utils/variables';
import styled, { css } from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableRow = styled.tr<{
  hasLink: boolean;
  color?: string;
  isFlat?: boolean;
  errored?: boolean;
}>`
  ${({ errored }) => errored && `border: 2px solid red};`}
  cursor: ${({ hasLink }) => (hasLink ? 'pointer' : 'default')};
  td {
    background-color: ${({ color }) => (color ? theme[color][100] : 'initial')};
  }
  &:nth-child(${({ isFlat }) => (!isFlat ? 'odd' : 'even')}) {
    background-color: ${theme.grey[100]};
  }
`;

export const TableContent = styled(Card)<{ isFlat?: boolean }>`
  display: flex;
  width: 100%;
  padding: 0;
  overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    thead > tr {
      position: sticky;
      top: 0;
      white-space: nowrap;
      background-color: ${({ isFlat }) =>
        !isFlat ? theme.white[100] : theme.grey[100]};
      z-index: 10;
    }

    & th,
    td {
      text-align: start;
    }

    & th {
      padding: 1rem;
      color: ${({ isFlat }) => (!isFlat ? theme.grey[500] : theme.grey[700])};
      font-size: ${font.sizes.sm};
      font-weight: ${font.weights.semibold};
      @media (max-width: 960px) {
        font-size: ${font.sizes.xxs};
        padding: 1rem 0.5rem;
      }
    }

    & td {
      padding: 1.2rem;
      font-weight: ${font.weights.normal};
      color: ${theme.black[100]};
      white-space: nowrap;
      @media (max-width: 960px) {
        font-size: ${font.sizes.xs};
        padding: 1rem;
      }

      &:first-child {
        padding-left: 2rem;
        @media (max-width: 960px) {
          padding-left: 1rem;
        }
      }
      &:last-child {
        padding-right: 2rem;
        text-align: end;
        @media (max-width: 960px) {
          padding-right: 1rem;
        }
      }
    }
  }
  ${({ isFlat }) => css`
    box-shadow: ${isFlat && 'none'};
  `}
`;

export const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  font-size: ${font.sizes.sm};
  color: ${theme.black[100]};
  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    font-size: ${font.sizes.xs};
    padding: 1rem;
  }
`;

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
`;

export const PageButtons = styled(Button)<{ isPrevOrNext?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${font.sizes.xs};
  width: 2rem;
  height: 2rem;
  padding: 0.5em;
  ${({ isPrevOrNext }) =>
    isPrevOrNext &&
    css`
      background-color: ${theme.grey[100]};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  &:hover {
    background-color: ${theme.grey[100]};
    color: ${theme.black[100]};
  }

  & svg path {
    stroke: transparent;
    fill: ${theme.grey[700]};
  }
`;
