export const useMultiRowParams = <T> (
  params: keyof T,
  state: Record<string, unknown>,
  formState: T,
  setFormState: React.Dispatch<React.SetStateAction<T>>,
) => {
  const dummyState: typeof state = {};
  Object.keys(state).forEach((key) => {
    if (typeof state[key] === 'number') {
      dummyState[key] = 0;
    } else {
      dummyState[key] = '';
    }
  });

  const addNewRow = () => {
    setFormState((prevState) => ({
      ...prevState,
      [params]: [...(prevState[params] as unknown as typeof state[]), state],
    }));
  };

  const deleteRow = (index: number) => {
    const values = [...(formState[params] as unknown as typeof state[])];
    values.splice(index, 1);

    if ((formState[params] as unknown as typeof state[]).length === 1) {
      setFormState((prevState) => {
        return {
          ...prevState,
          [params]: [dummyState]
        }
      })
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [params]: values
      }));
    }
    return null;
  };

  return { addNewRow, deleteRow };
}
