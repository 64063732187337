import { useState, useEffect } from 'react';

type SetURLSearchParams = (
  params: Record<string, string | null | undefined | string[]>,
  options?: {
    replace?: boolean;
  }
) => void;

function useSearchParams(): { params: URLSearchParams, setModalParams: SetURLSearchParams} {
  const [searchParams, setSearchParams] = useState<URLSearchParams>(() => {
    return new URLSearchParams(window.location.search);
  });

  useEffect(() => {
    const handlePopState = () => {
      setSearchParams(new URLSearchParams(window.location.search));
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const setParams: SetURLSearchParams = (params, options) => {
    const newParams = new URLSearchParams(window.location.search);

    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        newParams.delete(key);
      } else if (Array.isArray(value)) {
        newParams.delete(key);
        value.forEach(v => newParams.append(key, v));
      } else {
        newParams.set(key, value);
      }
    });

    const newSearch = newParams.toString();
    const newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;

    if (options?.replace) {
      window.history.replaceState(null, '', newUrl);
    } else {
      window.history.pushState(null, '', newUrl);
    }

    setSearchParams(newParams);
  };

  return {params: searchParams, setModalParams: setParams};
}

export { useSearchParams };