import { theme } from 'src/utils/variables';
import styled, { css } from 'styled-components';
import { Column } from '../../components/Flex';
import { injectModStyles } from '../../components';

export const Dropzone = styled(Column)<{ active?: boolean }>`
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  ${({ active }) => {
    return !active
      ? css`
          border: 1px solid ${theme.grey[50]};
        `
      : css`
          border: 1px solid ${theme.secondary[700]};
          /* filter: drop-shadow(0px 0px 5px ${theme.secondary[700]}); */
        `;
  }}
  ${(props) => props.modStyles && injectModStyles(props.modStyles)};
`;

export const FileInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
`;
