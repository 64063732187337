import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { onError } from '@apollo/client/link/error';
import { useMemo } from 'react';
import { useRowData, useToast } from 'src/state';
import { GET_NEW_ACCESS_TOKEN, API_URL, SUBSCRIPTION_ERROR } from 'src/constants';
import { getLocalStorage, setLocalStorage } from 'src/utils';
import jwtDecode from 'jwt-decode';
import { useLocation } from 'wouter';

interface DecodedToken {
  iat: number;
  exp: number;
  staffId: string;
  userId: string;
}

const customFetch = async (input: RequestInfo, init?: RequestInit) => {
  const token = getLocalStorage('token');
  const refreshToken = getLocalStorage('refreshToken');
  try {
    if (token) {
      const decoded: DecodedToken = jwtDecode(token);
      const decodedRefreshToken: DecodedToken = jwtDecode(refreshToken);
      if (decodedRefreshToken.exp * 1000 < Date.now()) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.reload();
        return;
      }
      if (decoded.exp * 1000 < Date.now()) {
        const client = new ApolloClient({
          cache: new InMemoryCache(),
          uri: process.env.REACT_APP_API_URL || API_URL,
        });

        await client
          .query({
            query: GET_NEW_ACCESS_TOKEN,
            variables: {
              refreshToken,
            },
          })
          .then((result) => {
            init = {
              ...init,
              headers: {
                ...init?.headers,
                Authorization: `Bearer ${result.data.getAccessToken.accessToken}`,
              },
            };
            setLocalStorage('token', result.data.getAccessToken.refreshToken);
            if (result.data.getRefreshToken) {
              setLocalStorage(
                'refreshToken',
                result.data.getAccessToken.refreshToken,
              );
            }
          });
      } else {
        init = {
          ...init,
          headers: {
            ...init?.headers,
            Authorization: `Bearer ${token}`,
          },
        };
      }
    }
  } catch {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.reload();
  }

  return fetch(input, init);
};

const authLink = createUploadLink({
  fetch: customFetch as (
    input: RequestInfo,
    init?: RequestInit | undefined,
  ) => Promise<Response>,
  uri: process.env.REACT_APP_API_URL || API_URL,
});

// const httpLink = createHttpLink({
//   uri: PROCESS.ENV.REACT_APP_API_URL || API_URL,
// });

// const authLink = setContext((_, { headers }) => {
//   const token = getLocalStorage('token');

//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : '',
//     },
//   };
// });

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const ApolloWrapper: React.FC = ({ children }) => {
  const { showToast } = useToast();
  const [, setLocation] = useLocation();
  const { updateRowData } = useRowData()

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          onError(({ response, networkError }) => {
            if (response?.errors?.length) {
              const { errors } = response;

              errors.map((error) => {
                if (error?.message === SUBSCRIPTION_ERROR) {
                  updateRowData("subscription", { isExpired: true });
                  return setLocation("/renew-subscription");
                }
                if (error?.extensions?.code === 'UNAUTHENTICATED') {
                  localStorage.removeItem('token');
                  localStorage.removeItem('refreshToken');
                  setLocation('/auth/signin');
                  // showToast('UNAUTHENTICATED!', 'error');
                  return error;
                }
                if (error?.message === 'Unauthorized') {
                  showToast('UNAUTHORIZED!', 'error');
                  return error;
                }
                showToast(error?.message, 'error');
                return error;
              });
            }
            if (networkError) {
              showToast(networkError.message, 'error');
              if (response?.errors) {
                response.errors = undefined;
              }
            }
          }),
          authLink,
        ]),
        cache: new InMemoryCache(),
        defaultOptions,
      }),
    [setLocation, showToast, updateRowData],
  );
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
