export enum PATIENT_STATUS {
  'INACTIVE' = 'grey',
  'CHECKED_IN' = 'green',
  'CHECKED_OUT' = 'grey',
  'ON MEDICATION' = 'yellow',
  'VISITING' = 'green',
  'ADMITTED' = 'red',
}

export enum INVOICE_STATUS {
  'PAID' = 'green',
  'UNPAID' = 'red',
}

export enum TODO_STATUS {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum STAFF_STATUS_ENUM {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  DISABLED = 'Disabled',
}

export enum VIEWS {
  WEEK = 'week',
  DAY = 'day',
  MONTH = 'month',
}

export enum APPOINTMENT_STATUS {
  ONGOING = 'ongoing',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed'
}

export enum FrequencyType {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

export enum MonthsType {
  JAN = 'JAN',
  FEB = 'FEB',
  MAR = 'MAR',
  APR = 'APR',
  MAY = 'MAY',
  JUN = 'JUN',
  JUL = 'JUL',
  AUG = 'AUG',
  SEP = 'SEP',
  OCT = 'OCT',
  NOV = 'NOV',
  DEC = 'DEC',
}

export enum WeekDayType {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}


export const BILLING_INVOICE_STATUS = {
  'UPCOMING': 'accent',
  'DUE': 'secondary',
  'OVERDUE': 'red',
  'PAID': 'green'
} as const
