import { SwitchWorkspace, Text } from 'src/components';
import { TourProvider } from '@reactour/tour';
import React, { useEffect, useState } from 'react';
import { LogoDark } from 'src/assets/icons';
import { SidebarProps } from 'src/types';
import useMediaQuery from 'src/utils/useMediaQuery';
import { TourContent, TourNavigation } from 'src/components/TourNavigation';
import { steps } from 'src/configs/tourSteps';
import { checkPermission, theme } from 'src/utils';
import { useStaff, useUser } from 'src/state';
import { SidebarContainer, SidebarItems } from './styled';
import { BuildSideBarItems } from './BuildSideBarItems';

export const Sidebar: React.FC<SidebarProps> = ({ config }) => {
  const isMobile = !useMediaQuery('(min-width: 960px)');
  const [expand, setExpand] = useState<boolean>(false);
  const [defaultOpen, setDefaultOpen] = useState(false);
  const { staffPermissions, staff } = useStaff();
  const { user } = useUser();
  const isTourComplete = user?.getUser?.isTourComplete;
  const staffGroup = staff?.getStaff?.staffGroup.name;

  useEffect(() => {
    const onboardingSteps =
      staff &&
      Object.values(staff?.getStaff?.facility?.organisation?.onboardingSteps);
    if (
      (staffGroup === 'Owner' &&
        !onboardingSteps.includes(false) &&
        !isTourComplete) ||
      (staffGroup !== 'Owner' && !isTourComplete)
    ) {
      setDefaultOpen(true);
    }
  }, [isTourComplete, staff, staffGroup, user]);

  return (
    <TourProvider
      steps={steps.filter((step) =>
        checkPermission(staffPermissions, step.permission),
      )}
      position="right"
      disableInteraction
      badgeContent={({ currentStep, totalSteps }) => (
        <Text>{`${currentStep + 1}/${totalSteps}`}</Text>
      )}
      onClickMask={() => null}
      beforeClose={() => setDefaultOpen(false)}
      showCloseButton={false}
      showBadge
      components={{
        Navigation: TourNavigation,
        Content: TourContent,
      }}
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: '10px',
          backgroundColor: '#FFFBF2',
        }),
        badge: (base) => ({ ...base, backgroundColor: theme.white[200] }),
      }}
    >
      <SidebarContainer
        className="sidebar"
        defaultOpen={defaultOpen}
        onMouseEnter={() => setExpand(true)}
        onMouseLeave={() => setExpand(false)}
      >
        {!isMobile && <LogoDark />}
        <SidebarItems>
          <BuildSideBarItems expand={expand} config={config} />
        </SidebarItems>
        {!isMobile && <SwitchWorkspace expand={expand} />}
      </SidebarContainer>
    </TourProvider>
  );
};
