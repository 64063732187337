import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  query SignIn($input: SigninInput!) {
    signIn(input: $input) {
      accessToken
      refreshToken
      staff {
        id: _id
        user {
          id: _id
          firstName
          lastName
          fullName
          address
          emails
        }
        facility {
          id: _id
          address
          state
          country
          organisation {
            id: _id
            name
            licenceNumber
            onboardingSteps {
              welcome
              inviteStaff
              setupServices
            }
            subscription {
              isAutoCharge
              billingFrequency
              freeTrialEnd
              isFreeTrial
              isGracePeriod
              name
              nextBillingDate
              period
              price
              status
            }
          }
        }
        staffGroup {
          id: _id
          name
        }
        permissions {
          id: _id
          name
        }
        status
      }
    }
  }
`;

export const GET_NEW_ACCESS_TOKEN = gql`
  query GetAccessToken($refreshToken: String!) {
    getAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
