import { useState, useEffect } from 'react';
import { Button, Column, H4, Row, Text } from 'src/components';
import { useAlert } from 'src/state/alert';
import { AlertIcon, CancelIcon, CheckCircleIcon } from 'src/assets/icons';
import { AlertCard, AlertChild, Overlay } from './styled';
import { ModalCloseButton } from '../Modal/styled';

export const Alert = () => {
  const { removeAlert, state } = useAlert();
  const {
    title,
    show,
    message,
    confirm,
    remove,
    confirmButton,
    closeButton,
    icon,
    confirmText,
    closeText,
    type,
    children,
    width,
    hasCancelBtn,
  } = state;
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    if (!show) {
      setLoading(false);
      setRemoving(false)
    }
  }, [show]);

  const handleConfirm = async () => {
    if (confirm) {
      try {
        setLoading(true);
        await confirm();
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    return removeAlert();
  };

  const handleRemove = async () => {
    if (remove) {
      try {
        setRemoving(true);
        await remove();
        setRemoving(false);
      } catch {
        setRemoving(false);
      }
      return;
    }
    return removeAlert();
  };

  let alertIcon;
  switch (type) {
    case 'success':
      alertIcon = <CheckCircleIcon />;
      break;
    case 'decision':
      alertIcon = <AlertIcon />;
      break;
    default:
      alertIcon = icon;
      break;
  }

  if (show) {
    return (
      <Overlay>
        <AlertCard
          width={width}
          data-testid="alert-container"
          modStyles={{ pt: hasCancelBtn ? 0 : '1.875rem' }}
        >
          {hasCancelBtn && (
            <ModalCloseButton
              data-testid="close-modal"
              onClick={removeAlert}
              variant="text"
              size="1rem"
              modStyles={{ ml: 'auto' }}
            >
              <CancelIcon />
            </ModalCloseButton>
          )}
          <AlertChild gap={1} align="center">
            {children || (
              <>
                {alertIcon}
                <Column maxWidth="25rem" align="center">
                  <H4 align="center" modStyles={{ my: 0 }}>
                    {title}
                  </H4>
                  <Text
                    data-testid="invoice-success-msg"
                    align="center"
                    size="sm"
                    modStyles={{ my: 0 }}
                  >
                    {message}
                  </Text>
                </Column>
                <Row gap={1} justify="center">
                  {closeButton || (
                    <Button
                      width="max-content"
                      variant="cancel"
                      color="black"
                      onClick={handleRemove}
                      isLoading={removing}
                      data-testid="alert-cancel"
                    >
                      {closeText || 'Close'}
                    </Button>
                  )}
                  {confirmButton || (
                    <Button
                      disabled={loading}
                      isLoading={loading}
                      onClick={handleConfirm}
                      width="max-content"
                      data-testid="alert-confirm"
                    >
                      {confirmText || 'Confirm'}
                    </Button>
                  )}
                </Row>
              </>
            )}
          </AlertChild>
        </AlertCard>
      </Overlay>
    );
  }
  return null;
};
