import { gql } from '@apollo/client';

export const VERIFY_PAYMENT = gql`
  query VerifyPayment($reference: String!) {
    verifyPayment(reference: $reference) {
      data
      message
      status
    }
  }
`;